import React, {Component} from 'react'
import PropTypes from "prop-types";
import {createTheme, MuiThemeProvider, withStyles} from "@material-ui/core";
import {compose} from "recompose";
import {connect} from "react-redux";
import {lightTheme} from "../../layout/themes";
import {translate} from 'react-admin'
import WaitingView from './WaitingView'
import ThankYou from './ThankYou'
import {PACKAGE_TYPE_ENTERPRISE, PACKAGE_TYPE_FREE, PACKAGE_TYPE_NORMAL} from '../home/constant'
import {LOAD_ORDER_DETAIL} from "../home/homeSaga";
import {parse} from 'query-string'
import {updateSelectedPackage} from "../home/homeReducers";

const styles = theme => ({
    // Package block
    container: {
        paddingLeft: 75,
        backgroundColor: '#ffffff',
        position: 'relative',
        height: '100%',
        paddingRight: '10vw',
        [theme.breakpoints.down('xs')]: {
            paddingRight: 5,
            paddingLeft: 5,
        }
    },
    content: {
        width: '100%',
        height: '100%',
        position: 'relative'
    }
})

class FinishByService extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            success: true,
            selectedPackage: {}
        }
    }

    UNSAFE_componentWillMount() {
        const query = parse(this.props.location.search, { ignoreQueryPrefix: true })
        if (query.orderId) {
            this.props.loadOrderDetail(query.orderId)
        }

        if (this.props.orderDetail) {
            // log.debug('Order detail load done!!!! Calculating selected package ', this.props.orderDetail)
            let selectedPackage = false
            this.props.orderDetail.paymentPlanIds.forEach(planOrPeriodId => {
                if (!selectedPackage) {
                    this.props.salePackages.forEach(pack => {
                        if (pack.id === planOrPeriodId)
                            selectedPackage = pack
                    })
                }
            })

            // log.debug('Calculate selected package done ', selectedPackage)
            this.props.updateSelectedPackage(selectedPackage)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.orderDetail && !!nextProps.orderDetail) {
            // log.debug('Order detail load done!!!! Calculating selected package ', nextProps.orderDetail)
            let selectedPackage = false
            nextProps.orderDetail.paymentPlanIds.forEach(planOrPeriodId => {
                if (!selectedPackage) {
                    nextProps.salePackages.forEach(pack => {
                        if (pack.id === planOrPeriodId)
                            selectedPackage = pack
                    })
                }
            })

            // log.debug('Calculate selected package done ', selectedPackage)
            this.props.updateSelectedPackage(selectedPackage)
        }
    }

    render() {
        let { classes, history, translate, loading, selectedPackage, orderDetail } = this.props
        const billing = orderDetail.status === 'APPROVED'
        // log.debug('Thank you ', selectedPackage, orderDetail)
        if (!selectedPackage)
            return null
            // return (<h1>selectedPackage null roi</h1>)

        if (!this.state.success)
            history.push('/home')

        let contextText = ''
        let link = false

        if (selectedPackage.tierPlanType === PACKAGE_TYPE_FREE || (selectedPackage.tierPlanType === PACKAGE_TYPE_NORMAL && billing)) {
            contextText = translate('resources.billing.thankYou.free_normal_done')
            link = { label: "Dashboard", href: "/report" }
        }

        if (selectedPackage.tierPlanType === PACKAGE_TYPE_NORMAL && !billing) {
            contextText = translate('resources.billing.thankYou.normal_not_done')
            link = { label: "cloud-admin@v-key.com", href: "mail:cloud-admin@v-key.com" }
        }

        if (selectedPackage.tierPlanType === PACKAGE_TYPE_ENTERPRISE)
            contextText = translate('resources.billing.thankYou.enterprise')

        return (
            <div className={classes.container} style={{minWidth: "700px", paddingLeft:0, paddingRight: 0}}>
                <div className={classes.content}>
                    <WaitingView loading={loading}>
                        <ThankYou history={history} title={"Thanks for choosing our service!"}
                                  success={true} loading={true}
                                  content={contextText}
                                  link={link}
                        />
                    </WaitingView>
                </div>
            </div>
        );
    }

    componentDidMount() {

    }
}

FinishByService.propTypes = {
    salePackages: PropTypes.array
};


const mapStateToProps = state => ({
    orderDetail: state.home.orderDetail,
    salePackages: state.home.salePackages,
    selectedPackage: state.home.selectedPackage,
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
            loadOrderDetail: (orderId) => ({
                type: LOAD_ORDER_DETAIL,
                payload: orderId
            }),
            updateSelectedPackage
        }
    ),
    withStyles(styles)
);

const EnhancedFinishByService = enhance(FinishByService);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const FinishByServiceWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedFinishByService {...props} />
    </MuiThemeProvider>
);

export default FinishByServiceWithTheme;
