import englishMessages from "ra-language-english";

export default {
  ...englishMessages,
  ip_address: "IP Address",
  required_field: "Required Field",
  invalid_url: "This URL is invalid",
  sign_in: "Sign In",
  sign_up: "Sign Up",
  forgot_pass: "Forgot password?",
  next: "Next",
  name: "Name",
  copied: "The value has been copied to Clipboard.",
  password: {
    name: "Password",
    validation: {
      minlength: {
        error: "Your password must contain at least 8 characters long",
      },
      uppercase: {
        error: "Your password must contain at least 1 uppercase character",
      },
      lowercase: {
        error: "Your password must contain at least 1 lowercase character",
      },
      number: { error: "Your password must contain at least 1 number" },
    },
  },
  ra: {
    ...englishMessages.ra,
    auth: {
      logout: "Sign Out",
    },
    action: {
      ...englishMessages.ra.action,
      close: "Close",
      cancel: "Cancel",
    },
    alert: "Alert",
    message: {
      ...englishMessages.ra.message,
      invalid_form: "Please enter valid values in the required fields",
    },
    session: {
      "was.expired": "",
    },
  },
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    home_page: "Home Page",

    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      name: "Dashboard",
      monthly_revenue: "Monthly Revenue",
      new_orders: "New Orders",
      pending_reviews: "Pending Reviews",
      new_customers: "New Customers",
      pending_orders: "Pending Orders",
      order: {
        items:
          "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
      },
      welcome: {
        title: "Welcome to V-OS Cloud",
        subtitle:
          "This is the Dashboard of the V-OS Cloud Access Gateway. Feel free to explore and modify the data of your account.",
        aor_button: "V-OS Cloud Access Gateway - Admin Site",
        demo_button: "Source for this demo",
      },
    },
    menu: {
      sales: "Sales",
      catalog: "Catalog",
      customers: "Customers",
      connectors: "Connectors",
      services: "Services",
      settings: "Settings",
    },
  },
  resources: {
    audit_log: {
      name: "Audit Log",
    },
    home: {
      package: {
        action: {
          trynow: "Try Now",
          subscribe: "Subscribe",
          contactus: "Contact Us",
        },
      },
      "goto.dashboard": "Go To Dashboard",
      guidelines: "Setup Guides",
      introduction: "Introduction",
      solutions: "Solutions",
      overview: "Overview",
      faq: "Support",
    },
    users: {
      name: "User |||| Users",
      username: "Username",
      customer: "Customer",
      display_name: "Display Name",
      email: "Email",
      status: "Status",
      reset_token: "Reset Token",
      inactivate: "Inactivate this user",
      send_email: "Send TS/APIN QR Code",
      dialog_tsapin_title: "Send Email",
      dialog_tsapin_body:
        "Enter an email address here. We will send the QR code of the digital token to this email.",
      notification: {
        token_reset_success: "User token is reset successfully.",
        token_reset_failure: "Failed to reset user token.",
        send_mail_success: "Email is sent successfully.",
        send_mail_failure: "Failed to send the email.",
        deactivate_success: "User has been deactivated",
        deactivate_failure: "Deactivate user failed",
      },
      validate_email_not_valid: "Please input a valid email.",
    },
    user_details: {
      name: "User Details",
      troubleTab: "troubleshootinglog",
      historyTab: "history",
    },
    signUp: {
      email: "Email",
      password: "Password",
      confirm_password: "Confirm Password",
      first_name: "First Name",
      last_name: "Last Name",
      company_name: "Company Name",
      company_phone: "Company Phone",
      activation_success: "Activation Successful!",
      activation_fail: "Activation Failed!",
      sign_in_if_exist: "Sign in to an existing V-OS Cloud account",
      create_new_vos_cloud_account: "Create a new V-OS Cloud account",
      agree_message: "I agree to the",
      term_of_use: "Terms of Use",
      activation_success_message:
        "Your account was activated successfully. \n" +
        "Please login to enjoy our services.",
      activation_fail_message:
        "This activation link is wrong or expired. Please contact V-Key admin to solve it.",
      validation: {
        required: "%{field_name} is required.",
        email_regex: "The email address is invalid.",
        pass_confirm: "Password and confirm password do not match.",
        email_used:
          "The email has already been used. Please use another email.",
      },
      token: {
        error: {
          data:
            "Your activation request is not valid. Please contact administrator - '%{email}'",
          expired:
            "Your activation request has expired. Please contact administrator - '%{email}'",
          activated:
            "Your activation request was processed before. Please contact administrator - '%{email}'",
        },
      },
      completed: {
        title: "Your account has been created",
        message: "Please check your email to complete your registration.",
        sentEmail: "We have sent you an email with an activation link.",
      },
    },
    themes: {
      name: "Theme |||| Themes",
      theme_name: "Theme Name",
      edit_title: "Edit Theme",
      create_title: "Create Theme",
      list_title: "Theme Details",
      show_title: "Show Theme",
      tooltip: {
        edit_theme_guide:
          "This theme is being assigned for one Token Pack, in order to edit, you need to revoke this theme by switching other theme for that Token Pack.",
      },
    },
    tokenpacks: {
      name: "Token Pack |||| Token Packs",
      field_feature_image: "Feature Image",
      tooltip_name: "This name will be shown on the V-Key app",
      tooltip_ldap:
        "The LDAP connector which connects to directory for authenticating users",
      tooltip_theme:
        "The ID of the theme which will be applied to the V-Key app for onboarding users",
      tooltip_services: "Usable services for the token pack",
      list_title: "Token Pack List",
      edit_title: "Edit Token Pack",
      create_title: "Create Token Pack",
      send_email: "Send Onboarding Email",
      send_email_button: "Send",
      fields: {
        icon: {
          label: "Icon",
          tooltip: "Icon of a token pack",
        },
        directory: {
          label: "Primary Directory",
          tooltip:
            "The LDAP connector which connects to directory for authenticating users",
        },
      },
      mail_dialog: {
        title: "Send Onboarding Email",
        body:
          "Enter the user's email address here. We will send an onboarding email to this user.",
        email_address: "Email Address",
      },
      dialog: {
        enableServiceConfirmationDialog: {
          title: "Confirmation",
          message:
            "The service will be enabled when you click on 'Save' button. However, you may need go to service to configure connectors, then start service after that.",
        },
        disableServiceConfirmationDialog: {
          title: "Confirmation",
          message:
            "The deselected service will be stopped in this token pack. Users on this token pack will not be able to use this service from the V-Key app. This change will be effective after the token pack is saved. Are you sure you want to disable this service? " +
            "Select CONFIRM if you want to proceed. Otherwise, select CANCEL.",
        },
      },
      notification: {
        send_mail_success: "Email is sent successfully.",
        send_mail_failure: "Failed to send the email.",
      },
      tabs: {
        info: {
          name: "Info",
        },
        white_list_ips: {
          name: "Trusted Networks",
        },
      },
    },
    serviceTemplates: {
      name: "Service Template |||| Service Templates",
      editPageTitle: "Edit Service Template",
      createPageTitle: "Create Service Template",
      viewPageTitle: "Service Template Details",
      fields: {
        templateName: {
          label: "Template Name",
          tooltip: "The name of the service template",
        },
        shortDescription: {
          label: "Short Description",
          tooltip: "The short description of the service template",
        },
        description: {
          label: "Description",
          tooltip: "The detailed description of the service template",
        },
        status: {
          label: "Status",
          tooltip: "The status of the service template",
        },
        serviceIcon: {
          label: "Service Icon",
          tooltip: "The icon of the service template",
        },
        selectConnectors: {
          label: "Connectors",
          tooltip: "Connectors of the service template",
        },
      },
      buttons: {
        cancelChangeServiceIcon: {
          label: "Cancel",
        },
        changeServiceIcon: {
          label: "Change Icon",
        },
        publishTemplate: {
          label: "Publish",
        },
        unpublishTemplate: {
          label: "Unpublish",
        },
      },
      dialogs: {
        publishTemplateConfirmationDialog: {
          title: "Publish Template Confirmation",
          message: "Are you sure you want to publish this template?",
        },
        unpublishTemplateConfirmationDialog: {
          title: "Unpublish Template Confirmation",
          message:
            "The unpublished template will no longer display. Are you sure you want to unpublish this template?",
        },
      },
      notificationMessages: {
        publishTemplateAction: {
          success: "Template is published successfully.",
          fail: "Error: Failed to publish template",
        },
        unpublishTemplateAction: {
          success: "Template is unpublished successfully.",
          fail: "Error: Failed to unpublish template",
        },
      },
    },
    serviceLaunchs: {
      name: "Service |||| Services",
      editPageTitle: "Manage Services",
      createPageTitle: "Launch Service",
      fields: {
        instanceName: {
          label: "Instance Name",
          tooltip: "The name of the service instance",
        },
        description: {
          label: "Description",
          tooltip: "The detailed description of the service",
        },
        status: {
          label: "Status",
          tooltip: "The status of the service",
        },
        configureConnectors: {
          label: "Configure Connectors",
          tooltip: "Configure connectors that the service will use",
        },
        icon: {
          label: "Icon",
          tooltip: "The icon of the service",
        },
      },
      buttons: {
        start: {
          label: "Start",
        },
        stop: {
          label: "Stop",
        },
        delete: {
          label: "Delete",
        },
        addUser: {
          label: "Add User",
        },
        removeUser: {
          label: "Remove User",
        },
        bulkAddUser: {
          label: "Add Selected Users",
        },
        bulkRemoveUser: {
          label: "Remove Selected Users",
        },
      },
      dialogs: {
        startServiceConfirmationDialog: {
          title: "Start Service Confirmation",
          message: "Are you sure you want to start this service?",
        },
        stopServiceConfirmationDialog: {
          title: "Stop Service Confirmation",
          message:
            "All users will not be able to use this service from the V-Key app once it is stopped. Are you sure you want to stop this service?",
        },
        addUserToDenyListDialog: {
          title: "Add User to 2FA Bypass List",
        },
        seamless_service_users: {
          addUserToDenyListConfirmationDialog: {
            title: "Add User to Seamless Login List Confirmation",
            message:
              "Are you sure you want to add '%{username}' user to Seamless Login list?",
          },
          removeUserFromDenyListConfirmationDialog: {
            title: "Remove User from Seamless Login List",
            message:
              "Are you sure you want to remove '%{username}' user from Seamless Login list?",
          },
          addBulkUsersToDenyListConfirmationDialog: {
            title: "Add Bulk Users to Seamless Login List Confirmation",
            message:
              "Are you sure you want to add all selected users to Seamless Login list?",
          },
        },
        deny_service_users: {
          addUserToDenyListConfirmationDialog: {
            title: "Add User to 2FA Bypass List Confirmation",
            message:
              "Are you sure you want to add '%{username}' user to 2FA bypass list?",
          },
          removeUserFromDenyListConfirmationDialog: {
            title: "Remove User from 2FA Bypass List",
            message:
              "Are you sure you want to remove '%{username}' user from 2FA bypass list?",
          },
          addBulkUsersToDenyListConfirmationDialog: {
            title: "Add Bulk Users to 2FA Bypass List Confirmation",
            message:
              "Are you sure you want to add all selected users to 2FA bypass list?",
          },
        },
        removeBulkUsersFromDenyListConfirmationDialog: {
          title: "Remove Bulk Users from 2FA Bypass List",
          message:
            "Are you sure you want to remove all selected users from 2FA bypass list?",
        },
      },
      tabs: {
        infoTab: "Info",
        denyUsersUsageTab: "2FA Bypass List",
        seamlessLoginTab: "Seamless Login List",
      },
      notificationMessages: {
        startServiceAction: {
          success: "Service is started successfully.",
          fail: "Error: Failed to start the service",
        },
        stopServiceAction: {
          success: "Service is stopped successfully.",
          fail: "Error: Failed to stop the service",
        },
        disableServiceAction: {
          success:
            "Disable service from this token pack, users will no longer see this service in V-Key app.",
          fail: "Error: Failed to disable the service",
        },
        enableServiceAction: {
          success:
            "Enable service for this token pack, users will see this service in V-Key app.",
          fail: "Error: Failed to enable the service",
        },
        deny_service_users: {
          removeUserFromDenyListAction: {
            success: "Users are removed from 2FA bypass list successfully.",
            fail: "Error: Failed to remove users from 2FA bypass list",
          },
          addUserToDenyListAction: {
            success: "Users are added to 2FA bypass list successfully.",
            fail: "Error: Failed to add users to 2FA bypass list",
          },
        },
        seamless_service_users: {
          removeUserFromDenyListAction: {
            success: "Users are removed from Seamless Login list successfully.",
            fail: "Error: Failed to remove users from Seamless Login list",
          },
          addUserToDenyListAction: {
            success: "Users are added to Seamless Login list successfully.",
            fail: "Error: Failed to add users to Seamless Login list",
          },
        },
      },
    },
    services: {
      serviceLaunch: {
        name: "Launch Service",
      },
      office365: {
        name: "Office 365",
      },
      sonicWallVpn: {
        name: "VPN",
      },
    },
    ldap: {
      name: "Directory |||| Directories",
      list_title: "Directories List",
      create_title: "Create Directory",
      edit_title: "Edit Directory",
      base_dn: "Base DN",
      ip_address: "IP Address / Domain Name",
      port: "Port",
      active: "ACTIVE",
      user_dn: "User DN",
      userPassword: "Password",
      userNamePattern: "User Login Pattern",
      secure: "Secured LDAP",
      test_connection: "Test Your Configuration",
      connect_success: "Connection is successful!",
      connect_failed: "Can't connect to LDAP server!",
      login_property: "Login Property",
      mapping_email: "Email Mapping Field",
      mapping_displayname: "Display Name Mapping Field",
      mapping_phone: "Phone Mapping Field",
      type: "Directory Type",
      azure_hybrid: "Using hybrid model",
      tenant_id: "Azure Tenant ID",
      tooltip_azure_hybrid:
        "A hybrid deployment provides the seamless look and feel of a single Exchange organization between an on-premises Exchange organization and Exchange Online",
      tooltip_mapping_email:
        "The field in LDAP/AD which is mapped to the email field ",
      tooltip_secure: "Secured Lightweight Directory Access Protocol",
      tooltip_basedn: "The point from where a server will search for users ",
      tooltip_ipaddress: "The IP address of the LDAP server ",
      tooltip_port: "The port of the LDAP server (e.g. 389)",
      tooltip_userdn:
        "An object inside LDAP that you bind to for giving you permissions to perform the desired actions ",
      tooltip_password: "The password of User DN",
      tooltip_login_property: "Identity property in the LDAP server ",
      tooltip_mapping_displayname:
        "The property in LDAP to be linked as user's display name",
      tooltip_mapping_phone:
        "The property in LDAP to be linked as user's phone number",
      tooltip_subscription:
        "The list of subscription packages that you've subscribed to",
      tooltip_tenant_id: "Your Azure tenant ID",
      description: {
        general_information:
          "Please choose the correct type because it's unable to change on a created connector.",
        administrator_consent:
          "Authorise this Directory connector to access your Azure Active Directory. This allows V-OS Cloud Directory connector the permissions to verify users' ID from your Azure AD tenant. There is no reading on the user's password or changing your user information. You can revoke this authorisation at any time.",
        user_readwrite_all:
          "Disable this hybrid mode flag if your user information is managed on Azure AD only. In this case, you need to authorize both read and write permissions.",
        user_read_all:
          "Enable this hybrid mode flag if your user information is synchronized between your on-premise AD and your Azure AD. In this case, you just need to authorize the read-only permission.",
        test_your_config: "Please input an end-user account.",
      },
      rest: {
        auth: {
          url: "API Url",
          title: "API for authentication",
          desc:
            "This API will be triggered when verifying user credential login V-Key App.",
        },
        getListUser: {
          url: "API Url",
          title: "API for get list active users",
          desc:
            "This API will be triggered when generating the V-Key Activation Code.",
        },
      },
    },
    radius: {
      name: "RADIUS",
      edit_title: "Edit RADIUS Connector",
      create_title: "Create RADIUS Connector",
      shared_secret: "Shared Secret",
      description_block: {
        title: "Configure Your RADIUS Domain",
        subtitle:
          "Create and configure one RADIUS Domain in your RADIUS Server with following information",
        radius_server_address: {
          label: "Radius Server Address",
          desc:
            "Copy and save this domain into Radius Server Address field in your RADIUS Domain",
        },
        radius_server_port: {
          label: "Radius Server Port",
          desc:
            "Copy and save this port into Radius Server Port field in your RADIUS",
        },
        radius_secret_key: {
          label: "Secret Password",
          desc:
            "Copy and save this password into Secret Password field in your RADIUS Domain",
        },
      },
      tooltip_name: "Name of the connector",
      tooltip_ipaddress:
        "The IP address of the RADIUS server (e.g. 192.168.1.1)",
      tooltip_shared_secret: "The secret key configured in the RADIUS server",
      fields: {
        name: {
          label: "Name",
          title: "The name of the connector.",
        },
        ipAddress: {
          label: "IP Address",
          title:
            "The IP address of the RADIUS server of your organization, e.g. SonicWall server, to which RADIUS connector responds to or receive authentication request from.",
        },
        shareSecret: {
          label: "Shared Secret",
          title:
            "The secret key which is configured in the RADIUS server of your organization. It is used to handshake between the RADIUS connector and your RADIUS server.",
        },
      },
    },
    saml2: {
      name: "SAML 2.0",
      create_title: "Create SAML 2.0 Connector",
      edit_title: "Edit SAML 2.0 Connector",
      connector_type:
        "Choose the type of your connector: Generic SAML2.0, Office 365...",
      config_desc:
        "To set up this connector, you may need to consult your Service Provider to get detail information for following fields. For verified Service Provider by V-Key, please refer to our guide. ",
      general_info_desc:
        "General information that specifies your connector uniquely",
      fields: {
        domain_name: "The domain for federating",
        uname: {
          title: "URL Suffix",
          desc: "The unique suffix for your URL",
        },
        x509cert: {
          desc:
            "The certificate provided by service provider to sign the respond. Set it if instructed by your service provider.",
        },
        provider: {
          name: "Name",
          entity_id: "Entity ID",
          assertion_consumer_service: "Assertion Consumer Service URL",
          logout_url: "Single Logout URL",
          login_url: "Service Provider Login URL",
          default_replay_state: "Default Replay State",
          desc: {
            name: "Name of your connector or service provider.",
            entity_id: "The unique identifier of the service provider",
            assertion_consumer_service:
              "The service provider endpoint which receives and processes SAML assertions.",
            logout_url:
              "The URL where your service provider receives SAML logout assertions.",
            login_url:
              "Enter the URL for IdP-initiated logins if your service provider specifies one. This URL provided by your service provider that will start a SAML authentication. Leave blank if not sure",
            default_replay_state:
              "If your service provider requires a specific RelayState parameter, enter it here. When set this parameter, all IdP-Initiated requests include this RelayState.",
          },
        },
        response: {
          name_id_format: "NameID Format",
          name_id_attr: "NameID Attribute",
          send_attr: "Send Attribute",
          signature_algorithm: "Signature Algorithm",
          sign_response: "Sign Response",
          sign_assertion: "Sign Assertion",
          map_attr: "Map Attribute",
          map_idp_attr: "Idp Attribute",
          map_reps_attr: "SAML Response Attribute",
          create_attr: "Create Attribute",
          desc: {
            name_id_format:
              "The format of NameID which specifies how the NameID is sent to the service provider.",
            name_id_attr:
              'The IdP attribute which identifies the user to the service provider. It is the authentication source attribute which used to identify the user to the service provider. This attribute is sent as the NameID and often a user\'s e-mail address ("mail" or "email") or username ("username").',
            send_attr:
              "Choose to send only NameID or all additional attributes in the SAML response. You may need to consult your Service Provider to configure the necessary attributes.",
            signature_algorithm:
              "Select the encryption strength supported by your service provider. Defaults to SHA-256.",
            sign_response:
              "Turn this option enabled if V-OS Cloud needs to sign the SAML response to the service provider. Uncheck the box if the response should not be signed.",
            sign_assertion:
              "Turn this option enabled if the V-OS Cloud needs to sign the SAML assertion to the service provider. Uncheck the box if the assertion should not be signed.",
            map_attr:
              "The IdP attributes to optionally rename in the SAML response (e.g. giveName to User.FirstName). If your service provider requires specific names for the attributes sent by the V-OS Cloud identity provider, they can be mapped to the required names here. Enter the attribute name from your authentication source on the left, and the new attribute name on the right. Consult your service provider's documentation for the required SAML Response Attribute names.",
            create_attr:
              "The attributes with hard-coded values to optionally send in the SAML response. If your service provider requires that the V-OS Cloud identity provider sends an attribute with a specific value, it can be defined here. Enter the new attribute name on the left, and the static attribute value on the right. Consult your service provider's documentation for the required attribute names.",
          },
        },
      },
      button: {
        save: "Save Configuration",
        download_xml_desc:
          "In stead of manual setting, if your service provider support to upload metadata in xml file, click here to download and upload to your service provider.",
      },
      errors: {
        uname_unique: "This connector name already existed",
      },
    },
    saml: {
      name: "SAML",
      domain: "Domain",
      create_title: "Create SAML Connector",
      edit_title: "Edit SAML Connector",
      prefer_host_name: "Preferred Host Name",
      trusted_cert: "Trusted Cert",
      federate_script: "Federate Script",
      unfederate_script: "Unfederate Script",
      sp_metadata: "SP Metadata",
      post_assert_url: "Post Assert URL",
      timeout: "Timeout",
      by_pass_2fa_user: "Bypass 2FA Users",
      tooltip_name: "Name of this connector",
      tooltip_domain: "Input your Office 365's domain",
      tooltip_bypass_2fa: "Skip 2FA authentication for specified users",
      vkey_menu: "SAML",
      url: "URL",
      fields: {
        url: {
          label: "VCAG URL",
          title:
            "The URL to V-OS Cloud Access Gateway which will be federated.",
        },
        protocol: {
          label: "Protocol",
          title: "The protocol name of SAML authentication.",
        },
        signOutPath: {
          label: "Sign out Postfix",
          title:
            "The specific postfix of the URL to the Sign out Page. This postfix will be concatenated with VCAG URL to build the full URL of the Sign out Page.",
        },
        signInPath: {
          label: "Sign in Postfix",
          title:
            "The specific postfix of the URL to the Sign in Page. This postfix will be concatenated with VCAG URL to build the full URL of the Sign in Page.",
        },
        metaPath: {
          label: "Meta Postfix",
          title:
            "The specific postfix of the URL to the Metadata Page. This postfix will be concatenated with VCAG URL to build the full URL of the Metadata Page.",
        },
        trustedCert: {
          label: "IDP SAML Certificate",
          title:
            "The Identity Provider SAML certificate which is used to federate with SAML service provider, such as Microsoft Office 365 service, to V-OS Cloud Access Gateway",
        },
        selectScript: {
          label: "Select Script",
          title:
            "Select the following Authentication mode of your script, then click <strong>GENERATE SCRIPT</strong> to generate the Federation Script:<br><br>" +
            "- <strong>Federation script:</strong> the Authentication mode will be switched to 'Federated'. You use this script to federate your Office 365 tenant to V-OS Cloud Access Gateway. Users will be redirected to V-OS Cloud Access Gateway’s login page while login to Office 365.<br>" +
            "- <strong>Unfederation script:</strong> the Authentication mode will be switched back to 'Managed', you use this script to stop federating your organization's Office 365 tenant from V-OS Cloud Access Gateway. Your users will login and authenticate directly on Office 365 tenant, no longer redirected to V-OS Cloud. It stops V-OS Cloud 2FA completely.<br><br>" +
            "Next, save and run script by Powershell on your Window PC which connected to Microsoft server of your organization’s Office 365 tenant. Refer to Help for detailed guideline of domain federation.",
        },
        federateScript: {
          label: "Federation Script",
          title: "",
        },
      },
      buttons: {
        generateFederateScript: {
          label: "Generate Script",
        },
        downloadFederateScript: {
          label: "Download Script",
        },
        copyScriptToClipBoard: {
          label: "Copy to Clipboard",
        },
      },
    },
    oidc: {
      name: "OpenID Connect",
      editPageTitle: "Edit OpenID Connect",
      createPageTitle: "Create OpenID Connect",
      fields: {
        clientId: {
          label: "Client ID",
          title: "The Client ID of Open ID connect protocol",
        },
        clientName: {
          label: "Client Name",
          title: "The Client Name of Open ID connect protocol",
        },
        redirectUrl: {
          label: "Redirect URL",
          title:
            "The URL - V-OS Cloud Access Gateway will callback to after succeed to authenticate the user.",
        },
        endpoints: {
          label: "Endpoints",
          title: "Show the configuration of the OIDC protocol endpoint.",
        },
      },
    },
    vos: {
      softtoken: "Soft Token",
      cloudconfig: "V-OS Cloud",
      editPageTitle: "Edit V-OS Cloud Settings",
      createPageTitle: "Create V-OS Cloud Settings",
      groups: {
        VCAGConfig: {
          label: "V-OS Cloud Access Gateway Configuration",
          tooltip:
            "Configuration for VCAG connecting to V-Key's Server suite on V-OS Cloud",
        },
        hostAppConfig: {
          label: "V-Key App Configuration",
          tooltip:
            "Configuration for V-Key app connecting to VCAG and V-Key's Server suite on V-OS Cloud",
        },
      },
      fields: {
        vkey2FAServer: {
          label: "V-OS Cloud URL",
          tooltip:
            "The URL of V-OS Cloud where V-Key’s Server suite can be reached by VCAG",
        },
        vkey2FACustomerId: {
          label: "Company ID",
          tooltip:
            "The ID of customer tenant which is defined in the V-Key Server suite",
        },
        vkey2FACustomerPassword: {
          label: "Company Password",
          tooltip:
            "The password of customer tenant which pairs with the Company ID. This is provided by V-Key in the assets",
        },
        vkey2FAHttpAthenticationMode: {
          label: "HTTP Authentication Mode",
          tooltip:
            "The authentication mode (DIGEST, BASIC, or Client Certificate) used while VCAG connects to V-OS Cloud, such as when VCAG calls V-OS Cloud APIs.",
        },
        vkey2FAAuthRequestPassType: {
          label: "Push Message Pass Type",
          tooltip:
            "The type of push message that VCAG sends to V-OS PKI Token Server components (ASP, SMP) in V-OS Cloud.",
        },
        vkey2FAAuthRequestNotifyMessageFlag: {
          label: "Notify Message Flag",
          tooltip:
            "The notify message flag for identifying whether the notify message data is plaintext or encrypted text, i.e. 0 if message is plaintext, 1 if message is encrypted text.",
        },
        vkey2FAAuthRequestMessageFlag: {
          label: "Message Flag",
          tooltip:
            "The message flag for identifying whether the 2nd pass message data is plaintext or encrypted text, i.e. 0 if message is plaintext, 1 if message is encrypted text.",
        },
        vkey2FAAuthRequestCallbackURL: {
          label: "VCAG Authentication Callback URL",
          tooltip:
            "The URL of V-OS Cloud Access Gateway that V-OS PKI Token Server components (ASP, SMP) call to return result of authentication",
        },
        vkey2FACustomerCertificate: {
          label: "HTTP Authentication Certificate",
          tooltip:
            'The certificate used for authentication between VCAG and V-OS Cloud Server components. It is set if HTTP Authentication Mode is "Client Certificate". Otherwise, set it to blank.',
        },
        urlApi: {
          label: "V-OS Cloud Access Gateway (VCAG) URL",
          tooltip:
            "The API URL of V-OS Cloud Access Gateway which client app connects to",
        },
        vtabProvision: {
          label: "V-OS Provisioning Server URL",
          tooltip: "The API URL of V-OS Provisioning component in V-OS Cloud",
        },
        vtabServer: {
          label: "V-OS Smart Token Server URL",
          tooltip: "The API URL of V-OS Smart Token component in V-OS Cloud",
        },
        vtabPkiServer: {
          label: "V-OS App Protection and PKI Server URL",
          tooltip:
            "The API URL of V-OS App Protection and PKI components in V-OS Cloud.",
        },
        status: {
          label: "V-OS Cloud Connector Status",
          tooltip:
            "The status of V-OS Cloud connector. It can be ACTIVE or INACTIVE.",
        },
      },
      dialogs: {
        activeConfirmationDialog: {
          title: "Activate V-OS Cloud Configuration",
          message:
            "Are you sure you want to activate this V-OS Cloud configuration?",
        },
      },
      notificationMessages: {
        activeAction: {
          success: "V-OS Cloud configuration is activated successfully.",
          fail: "Error: Failed to activate the V-OS Cloud configuration",
        },
      },
      saveSuccess: "V-OS Cloud configuration is saved successfully!",
    },
    customers: {
      name: "Customer |||| Customers",
      editPageTitle: "Edit Customer",
      createPageTitle: "Create Customer",
      viewPageTitle: "Customer Details",
      fields: {
        companyName: {
          label: "Company Name",
          tooltip: "The name of the organization",
        },
        contactEmail: {
          label: "Contact Email",
          tooltip: "The contact email of the organization",
        },
        contactPhone: {
          label: "Contact Phone",
          tooltip: "The contact phone of the organization",
        },
        createdDate: {
          label: "Date of Creation",
          tooltip: "The date of creation of the organization",
        },
        status: {
          label: "Status",
          tooltip: "The status of the organization",
        },
        logo: {
          label: "Logo",
          tooltip: "The logo of the organization",
        },
      },
      buttons: {
        inactive: "Deactivate",
        active: "Activate",
      },
      dialogs: {
        inactiveConfirmationDialog: {
          title: "Deactivate Customer",
          message:
            "Customers will not be able to login to the system and all services will be disabled. Are you sure you want to deactivate these customers?",
        },
        activeConfirmationDialog: {
          title: "Activate Customer",
          message: "Are you sure you want to activate these customers?",
        },
        contactUsSuccess: {
          title: "Contact Us",
          message:
            "Thanks for reaching out! It’s great to hear from you. We’ll be in touch soon.",
        },
      },
      notificationMessages: {
        inactiveAction: {
          success: "The customer is deactivated successfully.",
          fail: "Error: Failed to deactivate the customer",
        },
        activeAction: {
          success: "The customer is activated successfully.",
          fail: "Error: Failed to activate the customer",
        },
      },
      tabs: {},
      page: {
        delete: "Delete Customer",
      },
    },
    report: {
      name: "Dashboard",
      titles: {
        vpn: "VPN requests",
        top_vpn: "Top users using VPN",
        saml: "SAML requests",
        top_saml: "Top users using SAML",
        oidc: "OIDC requests",
        top_oidc: "Top users using OIDC",
        active_users: "Active users"
      },  },
        accounts: {
            name: "Administrator |||| Administrators",
            editPageTitle: "Edit Administrator",
            createPageTitle: "Create Administrator",
            viewPageTitle: "Administrator Details",
            changeYourPasswordPageTitle: "Change Password",
            fields: {
                email: {
                    label: "Email",
                    tooltip: "Email of an account",
                    changeEmailTooltip:
                        "You can change the login email here and use it from next login",
                    validation: {
                        required: "Email is required",
                        invalid: "Email address is invalid",
                    },
                },
                roles: {
                    label: "Roles",
                    tooltip: "Roles associated with the account",
                    validation: {
                        required: "Role is required",
                        duplicateSelectedRoles: "Selected roles are duplicated",
                    },
                },
                createdDate: {
                    label: "Date of Creation",
                    tooltip: "The date of creation of an account in the system",
                },
                status: {
                    label: "Status",
                    tooltip: "Active status",
                },
                oldPassword: {
                    label: "Old Password",
                    tooltip: "Old password",
                    validation: {
                        required: "Old Password is required",
                    },
                },
                newPassword: {
                    label: "New Password",
                    tooltip: "New password",
                    validation: {
                        required: "New Password is required",
                    },
                },
                confirmNewPassword: {
                    label: "Confirm New Password",
                    tooltip: "Confirm new password",
                    validation: {
                        required: "Confirm New Password is required",
                        notMatch:
                            "Password and Confirm New Password does not match",
                    },
                },
                firstName: {
                    label: "First Name",
                    tooltip: "The first name of the account user",
                    validation: {
                        required: "First Name is required",
                    },
                },
                lastName: {
                    label: "Last Name",
                    tooltip: "The last name of the account user",
                    validation: {
                        required: "Last Name is required",
                    },
                },
                mobilePhone: {
                    label: "Mobile Phone",
                    tooltip: "The mobile number of the account user",
                    validation: {
                        required: "Mobile Phone is required",
                    },
                },
                organizationName: {
                    label: "Organization name",
                },
                address: {
                    label: "Address",
                },
                contactNumber: {
                    label: "Contact Number",
                    tooltip: "The Contact Number of the account user",
                },
                otherInfo: {
                    label: "Other Info",
                },
                avatar: {
                    label: "Avatar",
                    tooltip: "The avatar of the account user",
                },
                roleEffect: {
                    label: "Role Effect",
                    tooltip:
                        "The effect of the selected roles on system resource",
                },
                enable2FA: {
                    label: "Enable 2 Factor Authentication",
                    tooltip: "",
                },
            },
            buttons: {
                inactive: "Deactivate",
                active: "Activate",
                updateProfile: "Update",
                updatePassword: "Update",
                updateSecurityConfig: "Update",
            },
            dialogs: {
                inactiveConfirmationDialog: {
                    title: "Deactivate Account",
                    message:
                        "These accounts will no be able to log in to the system. Are you sure you want to deactivate these accounts?",
                },
                activeConfirmationDialog: {
                    title: "Activate Account",
                    message:
                        "These accounts will be enabled. Are you sure you want to enable these accounts?",
                },
            },
            notificationMessages: {
                inactiveAction: {
                    success: "Account is deactivated successfully.",
                    fail: "Error: Failed to deactivate the account",
                },
                activeAction: {
                    success: "Account is activated successfully.",
                    fail: "Error: Failed to activate the account",
                },
                updatePasswordAction: {
                    success: "Password is updated successfully.",
                    fail: "Error: Failed to update password",
                },
                loadProfileAction: {
                    success: "Profile is loaded successfully.",
                    fail: "Error: Failed to load profile",
                },
                updateProfileAction: {
                    success: "Profile is updated successfully.",
                    fail: "Error: Failed to update profile",
                },
                loadSecurity2FAConfigInfo: {
                    success: "Security Config is loaded successfully.",
                    fail: "Error: Failed to load Security Config",
                },
                updateSecurityConfigAction: {
                    success: "Security Config is updated successfully.",
                    fail: "Error: Failed to update Security Config",
                    verifyPush2FA:
                        "To complete setting 2FA, you need to approve the push authentication request which has been sent to your phone.",
                },
            },
        },
        assets: {
            name: "Permission Asset |||| Permission Assets",
            editPageTitle: "Edit Permission Asset",
            createPageTitle: "Create Permission Asset ",
            viewPageTitle: "Permission Asset Details",
            configurePermissionPageTitle: "Configure Permission Asset",
            fields: {
                name: {
                    label: "Name",
                    tooltip: "Name of the permission asset",
                },
                description: {
                    label: "Description",
                    tooltip: "The description of the permission asset",
                },
                resourceType: {
                    label: "Resource Type",
                    tooltip: "The resource type of the permission asset",
                },
                actions: {
                    label: "Actions",
                    tooltip: "The list of permitted actions",
                },
            },
            buttons: {
                configurePermission: {
                    label: "Permission",
                },
                savePermissionConfig: {
                    label: "Save",
                },
            },
            dialogs: {},
            notificationMessages: {
                savePermissionConfigAction: {
                    success: "Permission asset is saved successfully.",
                    fail: "Error: Failed to save permission configurations",
                },
            },
        },
        roles: {
            name: "Role |||| Roles",
            editPageTitle: "Edit Role",
            createPageTitle: "Create Role",
            viewPageTitle: "Role Details",
            fields: {
                name: {
                    label: "Name",
                    tooltip: "The name of the role",
                },
                displayName: {
                    label: "Display Name",
                    tooltip: "The display name of the role",
                },
                description: {
                    label: "Description",
                    tooltip: "The description of the role",
                },
                scope: {
                    label: "Scope",
                    tooltip: "The scope of the role",
                },
            },
            buttons: {
                configurePermission: {
                    label: "Permission",
                },
                savePermissionConfig: {
                    label: "Save",
                },
            },
            dialogs: {
                addAccountToRoleDialog: {
                    title: "Add accounts to role",
                },
                addAccountToRoleConfirmationDialog: {
                    title: "Add Account to Role - Confirmation",
                    message:
                        "Are you sure you want to add '%{email}' account to this role?",
                },
                removeAccountFromRoleConfirmationDialog: {
                    title: "Remove Account from Role - Confirmation",
                    message:
                        "Are you sure you want to remove '%{email}' account from this role?",
                },
            },
            notificationMessages: {
                savePermissionConfigAction: {
                    success: "Permission asset is saved successfully.",
                    fail: "Error: Failed to save permission configuration",
                },
                addAccountToRoleAction: {
                    success: "Accounts are added successfully",
                    fail: "Error: Failed to add accounts",
                },
                removeAccountFromRoleAction: {
                    success: "Accounts are removed successfully",
                    fail: "Error: Failed to remove accounts",
                },
            },
            tabs: {
                infoTab: "Info",
                accountTab: "Accounts",
            },
        },
        commands: {
            name: "Order |||| Orders",
            title: "Order %{reference}",
            fields: {
                basket: {
                    delivery: "Delivery",
                    reference: "Reference",
                    quantity: "Quantity",
                    sum: "Sum",
                    tax_rate: "Tax Rate",
                    total: "Total",
                    unit_price: "Unit Price",
                },
                customer_id: "Customer",
                date_gte: "Passed since",
                date_lte: "Passed before",
                total_gte: "Min. amount",
                status: "Status",
                returned: "Returned",
            },
        },
        invoices: {
            name: "Invoice |||| Invoices",
            fields: {
                date: "Invoice Date",
                customer_id: "Customer",
                command_id: "Order",
                date_gte: "Passed since",
                date_lte: "Passed before",
                total_gte: "Min. amount",
                address: "Address",
            },
        },
        products: {
            name: "Poster |||| Posters",
            fields: {
                category_id: "Category",
                height_gte: "Min. height",
                height_lte: "Max. height",
                height: "Height",
                image: "Image",
                price: "Price",
                reference: "Reference",
                stock_lte: "Low Stock",
                stock: "Stock",
                thumbnail: "Thumbnail",
                width_gte: "Min. width",
                width_lte: "Max. width",
                width: "Width",
            },
            tabs: {
                image: "Image",
                details: "Details",
                description: "Description",
                reviews: "Reviews",
            },
        },
        categories: {
            name: "Category |||| Categories",
            fields: {
                products: "Products",
            },
        },
        reviews: {
            name: "Review |||| Reviews",
            detail: "Review Details",
            fields: {
                customer_id: "Customer",
                command_id: "Order",
                product_id: "Product",
                date_gte: "Posted since",
                date_lte: "Posted before",
                date: "Date",
                comment: "Comment",
                rating: "Rating",
            },
            action: {
                accept: "Accept",
                reject: "Reject",
            },
            notification: {
                approved_success: "Review approved",
                approved_error: "Error: Review is not approved",
                rejected_success: "Review rejected",
                rejected_error: "Error: Review is not rejected",
            },
        },
        segments: {
            name: "Segments",
            fields: {
                customers: "Customers",
                name: "Name",
            },
            data: {
                compulsive: "Compulsive",
                collector: "Collector",
                ordered_once: "Ordered once",
                regular: "Regular",
                returns: "Returns",
                reviewer: "Reviewer",
            },
        },
        myAccounts: {
            name: "My Account",
            menuName: "My Account",
            changeYourPasswordPageTitle: "Change Your Password",
            orderDetailPageTitle: "Order Details",
            resetPassword: "Reset Password",
            fields: {
                email: {
                    label: "Email",
                    tooltip: "The email of the account",
                },
                orderId: {
                    label: "Order ID",
                    tooltip: "The ID of the order",
                },
                status: {
                    label: "Status",
                    tooltip: "The status of order",
                },
                createDate: {
                    label: "Date of Creation",
                    tooltip: "The date of creation of the order",
                },
                expireDate: {
                    label: "Expiry Date",
                    tooltip: "The expiry date of the order",
                },
            },
            buttons: {},
            dialogs: {},
            tabs: {
                profileTab: "Profile",
                changePasswordTab: "Change Password",
                securityTab: "Security",
                orderTab: "Orders",
            },
            notificationMessages: {},
        },
        orders: {
            name: "Order |||| Orders",
            editPageTitle: "Edit Order",
            createPageTitle: "Create Order",
            detailPageTitle: "Order Details",
            parentMenu: "Orders Management",
            fields: {
                orderId: {
                    label: "Order ID",
                    tooltip: "The ID of the order",
                },
                service: {
                    label: "Service",
                    tooltip: "The service name",
                },
                plan: {
                    label: "Plan",
                    tooltip: "The order plan",
                },
                plans: {
                    label: "Plans",
                    tooltip: "The list of order plans",
                },
                amount: {
                    label: "Amount",
                    tooltip: "The amount of the order",
                },
                status: {
                    label: "Status",
                    tooltip: "The status of the order",
                    values: {
                        pending: "WAITING FOR PAYMENT",
                        cancel: "CANCELED",
                        active: "ACTIVE",
                        expired: "EXPIRED",
                    },
                },
                createDate: {
                    label: "Issue Date",
                    tooltip: "The issur date of the order",
                },
                expireDate: {
                    label: "Expiry Date",
                    tooltip: "The expiry date of the order",
                },
                numberOfUsers: {
                    label: "Number of users",
                    tooltip: "The maximum active users allowed",
                },
            },
            buttons: {
                cancel: {
                    label: "Cancel Order",
                },
                changePaymentPlan: {
                    label: "Change Payment Plan",
                },
                subscribe: {
                    label: "Subscribe",
                },
                approve: {
                    label: "Approve Order",
                },
                pay: {
                    label: "Complete Payment",
                },
            },
            dialogs: {
                cancelOrderConfirmationDialog: {
                    title: "Cancel Order Confirmation",
                    message: "Are you sure you want to cancel this order?",
                },
                changePaymentPlanConfirmationDialog: {
                    title: "Change Payment Plan Confirmation",
                    message:
                        "Are you sure you want to change payment plan for this order?",
                },
            },
            notificationMessages: {
                cancelAction: {
                    success: "Order is canceled successfully.",
                    fail: "Error: Failed to cancel order",
                },
            },
            tabs: {},
        },
        billing: {
            name: "Payment & Billing",
            management: "Billing Management",
            subscription: "Subscription |||| Subscriptions",
            thankYou: {
                free_normal_done: "You may start by visiting the ",
                normal_not_done:
                    "To complete activation, contact your partner/reseller with your account info \nor email",
                enterprise: "Our Sales team will contact you shortly",
            },
            stripe: {
                card: {
                    delete: {
                        confirm:
                            "This card will be removed from our system. Are you sure you want to remove this card? Select Yes to proceed. Otherwise, select No.",
                        title: "Removing Card",
                    },
                    "set.primary": {
                        confirm:
                            "The primary card will be automatically charged for bill payments in the future. Are you sure you want to set this card to primary? Select Yes to proceed. Otherwise, select No.",
                        title: "Setting Primary Card",
                    },
                },
            },
        },
        subscription: {
            editPageTitle: "Edit Subscription",
            createPageTitle: "Create Subscription",
            detailPageTitle: "Subscription Details",
            fields: {
                id: {
                    label: "Subscription ID",
                    tooltip: "The ID of the subscription",
                },
                plan: {
                    label: "Plan",
                    tooltip: "The subscription plan",
                },
                period: {
                    label: "Period",
                    tooltip: "The subscription period",
                },
                numberOfUsers: {
                    label: "Users",
                    tooltip: "The number of users of subscription",
                },
                amount: {
                    label: "Amount",
                    tooltip: "The amount of the subscription",
                },
                createDate: {
                    label: "Issue Date",
                    tooltip: "The issue date of the subscription",
                },
                expireDate: {
                    label: "Expiry Date",
                    tooltip: "The expiry date of the subscription",
                },
                status: {
                    label: "Status",
                    tooltip: "The status of the subscription",
                },
            },
            buttons: {
                editSubscription: {
                    label: "Edit Subscription",
                },
                upgradeSubscription: {
                    label: "Upgrade Subscription",
                },
                extendSubscription: {
                    label: "Extend Subscription",
                },
                detailSubscription: {
                    label: "Subscription Detail",
                },
            },
        },
        TrustedIp: {
            name: "Trusted IPs",
            edit_title: "Edit Trusted IP",
            create_title: "Create Trusted IP",
            tooltip: {
                ip_address:
                    "The IP address of your trusted network where your users’ V-Key App connected to",
            },
        },
        wizard: {
            welcome: {
                title: "Welcome",
                content_title: "Setup Wizard!",
                description:
                    "The Setup Wizard will guide you through the setup process for configuring V-OS Cloud services. Select the applicable setup wizard below to get started.",
            },
            dialog: {
                title: "Welcome To Setup Wizard",
            },
            confirm: {
                skip:
                    "You haven't configured completely for all services yet. Are you sure you want to exit this setup wizard?",
            },
            prerequisites: {
                title: {
                    vpn:
                        "You should have the following services/directories available before you start the configuration steps:",
                    o365:
                        "You should have the following services/directories available, and the following feature enabled for your Office 365 (O365) service before you start the configuration steps:",
                },
                item: {
                    vpn: {
                        item0:
                            "A directory for user authentication that can be accessed by external network",
                        item1:
                            "A directory for VPN service, if you use a different directory",
                        item2: "A working VPN service",
                    },
                    o365: {
                        item0:
                            "A directory for user authentication that can be accessed by external network",
                        item1:
                            "A directory for Office 365 service, if you use a different directory",
                        item2: "A working Office 365 service",
                        item3: "O365: Modern Authentication Feature",
                        item4: "O365: Domain Federation Feature",
                    },
                },
            },
        },
        https: {
            name: "HTTPS",
            editPageTitle: "Edit HTTPS",
            createPageTitle: "Create HTTPS",
            fields: {
                name: {
                    label: "Name",
                    title: "The name of Https connect protocol",
                },
                apiKey: {
                    label: "API Key",
                    title: "The API Key of Https connect protocol",
                },
                serviceId: {
                    label: "Service ID",
                    title: "The Service ID of Https connect protocol",
                },
            },
        },
        ldap2fa: {
            name: "LDAP",
            label: {
                detailForm: "LDAP",
            },
        },
        personalEmail: {
            register: {
                title: "Recovery email verified",
                success:
                    "You can use this email to backup your account.",
                fail:
                    "This link is not valid anymore. Don't hesitate to get in touch with administrators to solve the problem.",
            },
        },
        userReset: {
            byBackupEmail: {
                title: {
                    success: "The account has been reset successfully.",
                    fail: "The account failed to reset",
        },
        message: {
          success:
            "Please check the email sent to you to activate your account again.",
          fail: "Please contact your system administrator",
        },
      },
    },
  },
  error: {
    action: {
      notallow: "Action does not allow",
    },
    email: {
      existed: "Email already existed",
      invalid: "Invalid Email",
    },
    account: {
      invalid: {
        oldpassword: "Old Password is Incorrect",
        credential: "Invalid Account Credential",
      },
      email: {
        notfound: "Account with the email not found",
      },
      password: {
        required: "Password is required",
      },
      inactive: "Account is inactive",
      notactive: "Account is not active",
      accountId: {
        required: "Account ID is required",
        notfound: "Account ID not found",
      },
      ldap: {
        ou: {
          required: "ou is required for LDAP",
        },
        account: {
          existed: "LDAP account already exists",
        },
      },
      role: {
        required: "Account Role is required",
      },
      rootAccountCustomer: {
        notfound: "Root Account Customer not found",
      },
      resetToken: {
        invalid: "Invalid Reset Token",
      },
      security: {
        secondFA: {
          timeout:
            "The authentication request has been expired, please try again!",
          rejected: "The authentication request has been rejected.",
        },
      },
    },
    role: {
      name: {
        existed: "Role name already existed",
      },
    },
    billing: {
      order: {
        plan: {
          alreadySubscribeFreePlan: "You already subscribed to the Free Plan",
        },
        minNumberOfUser: "Minimum %{minUser} users required",
        lessThanCurrentNumberOfUser:
          "Only support to change the value greater than current subscription users. Current users is %{currUser}.",
      },
    },
    ldap: {
      testConnection: {
        mandatory:
          "Please input all mandatory fields before testing the connection",
      },
    },
    default: {
      internal: {
        server: {
          error:
            "The server encountered an internal error and was unable to complete your request",
        },
      },
    },
    connector: {
      radius: {
        "used.by.another":
          "There is one existing connector with this IP already. Please check your IP again!",
      },
    },
    "token.pack": {
      "list.size.invalid": "Service instance must belong to one token pack",
      "fields.name": {
        maxLength: "Name is invalid",
        specialChar: "Name is invalid",
        required: "Name is required",
      },
    },
    user: {
      "status.not.active":
        "You have not activated account on V-Key app yet. Please download and activate V-Key app before turning on this feature.",
    },
    "oidc.2fa.interaction_required": "Authentication failed.",
    idm: {
      config: {
        samlVer2Config: {
          uname: {
            existed: "SAML2 connector name already existed",
          },
        },
      },
    },
  },
  onelogin: {
    saml2: {
      idp: {
        entityid: "Entity ID",
        single_logout_service: {
          binding: "Logout URL Binding",
          url: "Logout URL",
        },
        single_sign_on_service: {
          binding: "Login URL Binding",
          url: "Login URL",
        },
        x509cert: "X509 Certificate",
      },
      sp: {
        nameidformat: "NameID Format",
      },
    },
  },
  desc: {
    idp: {
      title: "Identity Provider Information",
      desc:
        "In order to setup at your service provider side, you'll need to provide some information about V-OS Cloud SAML2.0 connector to your cloud application provider like EntityID, URL information, a metadata file, or a certificate. Pick below information and configure to your service provider. You may need to consult your service provider how to configure there. ",
    },
    onelogin: {
      saml2: {
        idp: {
          entityid: "The identity provider's entity ID",
          single_logout_service: {
            binding: "Description Logout URL Binding",
            url: "The identity provider logout URL",
          },
          single_sign_on_service: {
            binding: "Description Login URL Binding",
            url: "The identity provider login URL",
          },
          x509cert: "The identity provider certificate",
        },
        sp: {
          nameidformat: "Description NameID Format",
        },
      },
    },
  },
  "2fa.rejected": "The authentication request has been rejected.",
};
