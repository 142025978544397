import React, {Component} from 'react';
import {Button as MuiButton, Grid, TextField, withStyles, withWidth} from "@material-ui/core";
import {compose} from "recompose";
import {connect} from "react-redux";
import LocalStorage from "../../../../utils/services/storage";
import WaitingView from "../../../components/WaitingView";
import classNames from 'classnames'
import {Add} from '@material-ui/icons'
import {Button, translate} from 'react-admin'
import StripeSourceCard from './StripeSourceCard'
import StripeCardDetail from './StripeCardDetail'
import ConfirmationDialog from "../../../../internal/wizard_v2/components/ConfirmationDialog";
import {EDIT_ACTION, EXTEND_ACTION, UPGRADE_ACTION} from "../../../../subscription/SubscriptionList";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import BackArrowIcon from "../../../../Icons/BackArrow";
import qs from 'query-string'
import log from "../../../../utils/log";

const styles = theme => ({
	leftLabel: {
		textAlign: "right",
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		fontSize: 15,
		color: '#887295',
		paddingRight: '40px',
		[theme.breakpoints.down('xs')]: {
			paddingRight: 5
		}
	},
	rightControl: {
		paddingLeft: "30px !important",
		textAlign: 'left',
		color: 'rgb(122,83,141)',
		fontSize: '.8rem'
	},
	smallLabel: {
		fontSize: '.6rem',
		marginBottom: 5,
		color: '#c3c3c3'
	},
	mr2: {
		marginRight: theme.spacing(2)
	},
	mt2: {
		marginTop: theme.spacing(2)
	}
})

const stripe = window.stripe('pk_test_X55Al3UGc2Q7SsXBiIEKCH1B00olDehLe1')

class StripeCheckoutForm extends Component {

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.deleteCard = this.deleteCard.bind(this);
		this.setSelectedCard = this.setSelectedCard.bind(this);

		const elements = stripe.elements()
		this.card = elements.create('card', {
			hidePostalCode: true
		})

		this.card.on('change', (e) => {
			// log.debug('Card was changed')
		})

		const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
		log.info('StripeCheckoutForm::constructor ', query)

		this.state = {
			selectedCard: props.cards.length === 0 ? false : {...props.cards[0], isPrimary: true},
			inputNewCard: false,
			tempCard: false,
			orderId: query.orderId
		}
	}

	setSelectedCard(card) {
		this.setState({selectedCard: {...card, isPrimary: true}})
	}

	goNext() {
		/*const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
		query.done = true
		this.props.history.push(`/home?${ qs.stringify(query) }`)*/
	}

	submitOrder(tokenId, bPay = true) {
		const {orderDetail, subscribePackage, selectedPackage, upgradePackage, extendPackage, editPackage} = this.props
		let action = orderDetail.action;

		if (action && action === UPGRADE_ACTION) {
			upgradePackage(selectedPackage, {
				...orderDetail,
				stripeTokenId: tokenId,
				sourceId: this.state.selectedCard && bPay ? this.state.selectedCard.id : null
			})
		} else if (action === EXTEND_ACTION) {
			extendPackage(selectedPackage, {
				...orderDetail,
				stripeTokenId: tokenId,
				sourceId: this.state.selectedCard && bPay ? this.state.selectedCard.id : null
			})
		} else if (action === EDIT_ACTION) {
			editPackage(selectedPackage, true, {
				...orderDetail,
				stripeTokenId: tokenId,
				sourceId: this.state.selectedCard && bPay ? this.state.selectedCard.id : null
			})

		} else {
			subscribePackage(selectedPackage, {
				...orderDetail,
				stripeTokenId: tokenId,
				sourceId: this.state.selectedCard && bPay ? this.state.selectedCard.id : null
			})
		}
	}

	handleSubmit(ev) {
		ev.preventDefault();

		const customerId = LocalStorage.instance.getCustomerId()
		const {payAnOrder, orderDetail, subscribePackage, selectedPackage, upgradePackage, extendPackage, editPackage} = this.props

		if (stripe && this.state.inputNewCard) { // Input new card
			stripe
				.createToken(this.card, {name: this.cardholderName.value})
				.then((payload) => {
					if (payload.hasOwnProperty('error')) {
						log.warn('Token error ', payload)
					} else {
						if (!orderDetail || !orderDetail.id) { // Create new order and process payment
							this.submitOrder(payload.token.id)
						} else {
							// log.debug('Pay existed order')
							payAnOrder(customerId, orderDetail.id, payload.token.id, this.state.selectedCard ? this.state.selectedCard.id : null) // Pay existed order only
						}
						// After charge success -> go to next step
						// this.goNext()
					}
					// Use token for create Stripe customer
				})
				.catch(e => {
					log.warn(e)
				})
		} else if (stripe) { // Reuse saved card
			if (!orderDetail || !orderDetail.id) { // Create new order and process payment
				// // log.debug('Create new order and process payment')
				this.submitOrder(null)

			} else {
				payAnOrder(customerId, orderDetail.id, null, this.state.selectedCard ? this.state.selectedCard.id : null)
			}
			// this.goNext()
		}
	}

	deleteCard(card) {
		this.setState({tempCard: card}, () => this.confirm.confirm())
	}

	render() {
		const {classes, orderDetail, selectedPackage, periods, loading, cards, translate, updateCurrentStep, dispatch} = this.props
		const {selectedCard} = this.state

		if (!periods || periods.length === 0 || !orderDetail.paymentPlanIds)
			return null

		let period = false
		orderDetail.paymentPlanIds.map(planId => {
			if (!period) {
				const filteredPeriods = periods.filter(p => p.id === planId)
				if (filteredPeriods && filteredPeriods.length > 0)
					period = filteredPeriods[0]
			}
		})

		const inputNewCard = cards.length === 0 || this.state.inputNewCard

		// log.debug('Stripe Checkout Form render', periods, period, orderDetail)

		return (
			<form className="checkout" onSubmit={this.handleSubmit}>
				<WaitingView loading={loading}>
					<Grid container spacing={24}>
						<Grid item xs={3} className={clsx(classes.leftLabel)} style={{fontSize:"15px"}}>
							Subscription
						</Grid>
						<Grid item xs={9} className={clsx(classes.rightControl)} style={{fontSize:"15px"}}>
							{"Enterprise Package"} for&nbsp;
							{orderDetail.numberOfUsers} users per {period.periodCount > 1 ? `${period.periodCount} ` : ""}{period.period.toLowerCase()}
							{period.periodCount > 1 ? 's' : ''}
						</Grid>

						<Grid item xs={3} style={{marginTop:"30px"}}></Grid>
						<Grid item xs={9} className={classNames(classes.leftLabel, classes.rightControl, classes.mt2)}
							  style={{paddingTop: 0, paddingBottom: 0, marginTop:"30px"}}>
							<div style={{display: 'flex', justifyContent: 'center', width: '100%' }}>
								<IconButton onClick={e => {
									window.globalHistory.goBack()
								}} style={{marginRight: 60}}><BackArrowIcon style={{width: 15.75}}/></IconButton>
								<div style={{ flex: 1 }}></div>
								<MuiButton variant="outlined" color="secondary" className={classes.mr2} onClick={evt => {
									if (!this.state.orderId)
										this.submitOrder(null, false)
									else {
										window.globalHistory.push(`/my-account/subscription?` + qs.stringify({
											orderId: this.state.orderId,
											done: true,
											// step: 1
										}))
									}
								}}>
									Place Order
								</MuiButton>
							</div>
						</Grid>
					</Grid>
				</WaitingView>
				<ConfirmationDialog translate={translate}
					width={this.props.width}
					onCancel={() => { // log.debug('onCancel callback')
					}}
					onOk={() => {
						if (this.state.tempCard)
							this.props.deleteCard(this.state.tempCard.id)
					}}
					options={{
						title: translate('resources.billing.stripe.card.delete.title'),
						body: translate('resources.billing.stripe.card.delete.confirm')
					}}
					ref={ref => this.confirm = ref}/>
			</form>
		);
	}

	mountCardElement() {
		if (document.getElementById('card-element')) {
			this.card.mount('#card-element')
		}
	}

	componentDidMount() {

	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (this.props.cards.length === 0 && nextProps.cards.length > 0) {
			this.setState({selectedCard: {...nextProps.cards[0], isPrimary: true}, inputNewCard: false})
		}

		if (nextProps.cards.length === 0) {
			this.setState({selectedCard: false, inputNewCard: true}, () => this.mountCardElement())
		}
	}
}

const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch) => ({})
const enhance = compose(
	translate,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
	withWidth()
);

export default enhance(StripeCheckoutForm);
