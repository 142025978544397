import React, {Component, Fragment, useEffect, useState} from "react";
import {createTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

import PropTypes from "prop-types";
import {Grid, IconButton, TextField, withWidth} from "@material-ui/core";
import SaleProIcon from '../../Icons/SalePackagePro'
// dummy period data
import WaitingView from '../components/WaitingView'
import qs, {parse} from "query-string";
import BackArrowIcon from '../../Icons/BackArrow'
import ForwardArrowIcon from '../../Icons/ForwardArrow'
import {lightTheme} from "../../layout/themes";
import {translate} from 'react-admin'
import compose from 'recompose/compose';
import {EDIT_ACTION, EXTEND_ACTION, UPGRADE_ACTION} from "../../subscription/SubscriptionList";
import {calculateAmount} from './homeApi';
import MultipleUserSelection from './component/MultipleUserSelection/MultipleUserSelection'
import Tooltip from "@material-ui/core/Tooltip";
import Fade from '@material-ui/core/Fade';
import InfoIcon from '@material-ui/icons/Info';
import {useLocation} from "react-router";
import log from "../../utils/log";

const styles = theme => {
	return {
		// Package block
		container: {
			backgroundColor: '#ffffff',
			position: 'relative',
			height: '100%',
			[theme.breakpoints.down('xs')]: {
				paddingLeft: 15
			}
		},
		leftLabel: {
			textAlign: "right",
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			fontSize: 15,
			color: '#887295',
			paddingRight: '40px',
			[theme.breakpoints.down('xs')]: {
				paddingRight: 5
			}
		},
		rightControl: {
			paddingLeft: "30px !important",
			display: 'flex',
		},
		textBottom: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end'
		},
		validationError: {
			...theme.SignUp.validationError
		},
		rootInput: {
			minWidth: 0
		},
		totalPaymentDevider: {
			borderBottom: "1px solid #887295",
			paddingBottom: 15
		},
		mt2: {
			marginTop: theme.spacing(2)
		}
	}
}

export const Period = withStyles({
	default: {
		maxWidth: 110,
		minHeight: 30,
		border: '1px solid #ece1f7',
		marginRight: 15,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: "relative",
		padding: "10px 0px 5px 0px"
	},
	title: {
		fontSize: '1.1rem',
		color: '#5f2685',
		textAlign: 'center',
	},
	detail: {
		fontSize: '1rem',
		color: '#75658b',
		textAlign: 'center'
	},
	selected: {
		borderColor: '#be92fa'
	},
	discount: {
		fontSize: "0.6rem",
		bottom: 2,
		width: "100%",
		textAlign: "center",
		color: "red",
		fontWeight: 500,
	}
})(({classes, children, period, selectedPeriod, amount, width, maxWidth, existedProSubscription, ...props}) => {
	const className = period.id === selectedPeriod.id ? classNames(classes.default, classes.selected) : classes.default
	return (
		<div className={className} style={maxWidth ? {maxWidth: maxWidth} : {}} {...props}>
			<div className={classes.title} style={{fontSize: width === 'xs' ? '.8rem' : '1.1rem'}}>{period.name}</div>
			{/*<div className={classes.detail}
				 style={{fontSize: width === 'xs' ? '.7rem' : '.9rem'}}>{`${period.currency} ${period.nonCalculateAmount} /User`}</div>*/}
			{/*{period.discountPercent &&
                <div className={classes.discount}>{period.currency} {period.amount * (100 - period.discountPercent) / 100}/user/month - {period.discountPercent}% Savings</div>
            }*/}
			<div style={{flex: 1}}></div>
			{/*{(period.freeMonth + (!existedProSubscription ? period.firstTimeFreeMonth : 0) > 0) &&
			<div className={classes.discount} style={{cursor: "pointer"}}>
				<Tooltip TransitionComponent={Fade}
						 title={<div>
							 {!existedProSubscription && period.firstTimeFreeMonth > 0 &&
							 <div>{period.firstTimeFreeMonth}-Month FREE Trial for 1st Professional Subscription </div>}
							 {period.freeMonth > 0 &&
							 <div>{period.freeMonth}-Month Discount for {period.periodCount}-Year Payment Plan</div>}
						 </div>}
				>
					<div><span
						style={{height: 10}}>{period.freeMonth + (!existedProSubscription ? period.firstTimeFreeMonth : 0)}-Month Discount</span>
						<span><InfoIcon style={{height: 10, width: 10}}/></span></div>
				</Tooltip>
			</div>
			}*/}
		</div>
	)
});

Period.propTypes = {
	period: PropTypes.object
}

/**
 * When user selected professional plan
 */
const ReviewPlan = (props) => {

	const initialState = {
		selectedPeriod: {},
		numberOfUsers: 10,
		amount: 1,
		trialTerm: null,
		removeUsers: null
	};
	const [data, setData] = useState(initialState)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	const [action, setAction] = useState(null)
	const [displayPeriodId, setDisplayPeriodId] = useState("")
	const [calResult, setCalResult] = useState({
		currency: "USD",
		discountAmount: null,
		discountPercent: 0,
		finalAmount: "0",
		totalAmount: "0",
		trialTerm: null
	})
	const location = useLocation()

	useEffect(() => {
		const {tier, periods, subscriptionDetail, reviewPlan: {selectedPeriod, numberOfUsers}} = props
		const {action} = parse(location.search)

		let initSelectedPeriod = periods.length > 0 ? periods[0] : false;

		setData({
			...data,
			selectedPeriod: selectedPeriod ? selectedPeriod : initSelectedPeriod,
			numberOfUsers: numberOfUsers ? numberOfUsers : subscriptionDetail ? subscriptionDetail.numberOfUsers : 10
		})

		setAction(action)
		setLoading(loading)
		props.getExistSubscription()

	}, [])

	useEffect(() => {
		let periods = props.periods;
		let subscriptionDetail = props.subscriptionDetail;

		if (subscriptionDetail) {

			let selectedPeriod = (periods && periods.length > 0) ? periods[0] : false;

			if (subscriptionDetail && subscriptionDetail.periodPaymentPlan && periods && periods.length > 0) {
				selectedPeriod = periods.find(p => p.id === subscriptionDetail.periodPaymentPlan.id)
			}

			setData({
				...data,
				selectedPeriod: selectedPeriod,
				numberOfUsers: subscriptionDetail.numberOfUsers
			})
		}
	}, [props.periods, props.subscriptionDetail])

	useEffect(() => {
		calTotalCost().then(() => log.debug("calTotalCost ::: DONE"))
	}, [data, props.subscriptionDetail])

	useEffect(() => {
		const {periods, subscriptionDetail} = props
		const {action} = parse(location.search)
		setDisplayPeriodId(getDisplayPeriod(action, periods, props.subscriptionDetail))
	}, [props.subscriptionDetail])

	useEffect(() => {
		const {periods} = props;
		const step = qs.parse(location.search).step;
		const action = qs.parse(location.search).action;
		if(step === "1" && ! action){
			console.log("resetting state ...");
			let initSelectedPeriod = periods.length > 0 ? periods[0] : false;
			setData({
				...initialState,
				selectedPeriod: initSelectedPeriod,
				numberOfUsers: 10
			});
		}
	},[location])

	/*componentDidMount() {
		props.getExistSubscription()
	}*/

	const calTotalCost = async () => {

		const {subscriptionDetail} = props
		const tier = props.selectedPackage

		let subscriptionId;
		if (subscriptionDetail) {
			subscriptionId = subscriptionDetail.id;
		}

		let req = {
			action,
			numberOfUsers: data.numberOfUsers,
			tierId: tier.id,
			periodId: data.selectedPeriod.id,
			subscriptionId,
			subscriptionDetail
		};

		// props.setLoading(true)
		calculateAmount(req).then((rsp) => {
			setCalResult({
				...calResult,
				...rsp.json
			})
			setLoading(false)
		});
	}

	const validateMinUser = (value, minUser) => {
		if (value < minUser) {
			return {numberOfUsers: "error.billing.order.minNumberOfUser"};
		}

		return undefined;
	}

	const validateChangeNumberOfUser = (action, value, currUser) => {
		if (action === EDIT_ACTION && value <= currUser) {
			return {numberOfUsers: "error.billing.order.lessThanCurrentNumberOfUser"};
		}

		return undefined;
	}

	const getDisplayPeriod = (action, periods, subscriptionDetail) => {
		let initSelectedPeriod = periods.length > 0 ? periods[0] : false;
		let displayPeriodId = undefined;

		if ((action === EXTEND_ACTION || action === EDIT_ACTION)
			&& subscriptionDetail && periods.length > 0) {
			initSelectedPeriod = periods.find(p => p.id === subscriptionDetail.periodPaymentPlan.id)

			if (action === EDIT_ACTION) {
				// only display this period in edit sub
				displayPeriodId = initSelectedPeriod.id;
			}
		}

		return displayPeriodId;
	}

	const submitOrder = () => {

		const {onSubmit, tier, subscriptionDetail} = props

		const minUser = tier.minUser ? tier.minUser : 1
		let error = validateMinUser(data.numberOfUsers, minUser);
		if (error) {
			setError(error)
			return;
		}

		// for edit case: currently only support increase number of users
		if (action && action === EDIT_ACTION) {
			error = validateChangeNumberOfUser(action, data.numberOfUsers, subscriptionDetail.numberOfUsers);
			if (error) {
				setError(error)
				return;
			}
		}

		let subscriptionId;
		if (subscriptionDetail) {
			subscriptionId = subscriptionDetail.id;
		}

		let submitData = {};
		if (action && action === UPGRADE_ACTION) {
			submitData = {
				periodId: data.selectedPeriod.id,
				numberOfUsers: data.numberOfUsers,
				action: action,
				subscriptionId: subscriptionId
			}
		} else if (action === EXTEND_ACTION) {

			submitData = {
				periodId: data.selectedPeriod.id,
				numberOfUsers: data.numberOfUsers,
				action: action,
				subscriptionId: subscriptionId,
				removeUsers: data.removeUsers
			}
		} else if (action === EDIT_ACTION) {
			submitData = {
				periodId: data.selectedPeriod.id,
				numberOfUsers: data.numberOfUsers,
				action: action,
				subscriptionId: subscriptionId
			}
		} else {
			submitData = {
				periodId: data.selectedPeriod.id,
				numberOfUsers: data.numberOfUsers
			}
		}

		if (onSubmit) {
			onSubmit(submitData)
		}
	}

	const onUsersSelected = (userIds) => {

		if (action !== EXTEND_ACTION) {
			return;
		}

		setData({
			...data,
			removeUsers: userIds
		})
	}

	const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

			const negativeSign = amount < 0 ? "-" : "";

			let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
			let j = (i.length > 3) ? i.length % 3 : 0;

			return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
		} catch (e) {
			log.debug(e)
		}
	};

	const {
		classes, history, translate, width, periods, subscriptionId,
		subscriptionDetail, currentActiveUsersSubscription,
		subscriptions
	} = props
	const tier = props.selectedPackage
	let selectedPeriod = data.selectedPeriod

	if (!selectedPeriod)
		selectedPeriod = props.reviewPlan.selectedPeriod

	let existedProSubscription = false;

	const applyTrial = action !== EXTEND_ACTION && action !== EDIT_ACTION && data.trialTerm;
	const isDisplayCurrentActiveUser = action === EXTEND_ACTION || action === EDIT_ACTION;
	const currentActiveUsers = currentActiveUsersSubscription ? currentActiveUsersSubscription : 0;
	const currentNumberOfUsers = subscriptionDetail ? subscriptionDetail.numberOfUsers : 0;
	const requestNumberOfUsers = data.numberOfUsers;
	const isDisplayRemoveUsersComp = action === EXTEND_ACTION && requestNumberOfUsers < currentNumberOfUsers && requestNumberOfUsers < currentActiveUsers;

	// const userCost = selectedPeriod ? (applyTrial ? selectedPeriod.calculatedAmount : selectedPeriod.nonCalculateAmount) : 0

	if (subscriptions) {
		subscriptions.forEach(sub => {
			if (sub.tierPlanType === "NORMAL")
				existedProSubscription = true
		})
	}

	let freeMonth = selectedPeriod.freeMonth
	if (!existedProSubscription)
		freeMonth += selectedPeriod.firstTimeFreeMonth

	return (
		<div className={classes.container} style={{maxWidth: "450px"}}>
			<div style={{display: 'flex', marginBottom: width === 'xs' ? 15 : 45}}>
				{/*<SaleProIcon style={{visibility:"hidden",width: 31.5, height: 26.25, marginRight: 20}}/>*/}
				<div style={{
					fontSize: 22.5,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				}}>{ ! displayPeriodId ? "Create Subscription" : "Upgrade Subscription"}</div>
			</div>
			{/*{ !loading ? */}
			<Grid container spacing={24}>
				<Grid item xs={3} className={classes.leftLabel}>
					Period:
				</Grid>
				<Grid item xs={9} className={classes.rightControl}>
					{periods.map((period, index) => {

						if (displayPeriodId == undefined) {
							// display all
							return (<Period period={period} selectedPeriod={selectedPeriod}
											existedProSubscription={existedProSubscription}
											onClick={e => {
												setData({
													...data,
													selectedPeriod: period
												})
											}} width={props.width}/>)

						} else if (period.id === displayPeriodId) {
							return (<Period maxWidth={250} period={period}
											existedProSubscription={existedProSubscription}
											selectedPeriod={data.selectedPeriod} onClick={e => {
								setData({
									...data,
									selectedPeriod: period
								})
							}} width={props.width}/>)
						}

					})
					}
				</Grid>

				{isDisplayCurrentActiveUser &&
				<Fragment>
					<Grid item xs={3} className={classes.leftLabel} style={{ marginTop: 24 }}>
						Current Active Users:
					</Grid>
					<Grid item xs={9} className={classes.rightControl} style={{ marginTop: 24, justifyContent: "center" }}>
						<span>{currentActiveUsers}</span>
					</Grid>
				</Fragment>
				}

				<Grid item xs={3} className={classes.leftLabel}>
					Number of Users:
				</Grid>
				<Grid item xs={9} className={classes.rightControl} style={{justifyContent: "flex-end"}}>
					<TextField value={data.numberOfUsers} type={"number"}
							   error={error !== false}
							   style={{
								   width: 'calc(50% - 15px)',
								   maxWidth: "10rem",
								   fontSize: 17,
								   color: '#887295',
								   marginTop: 15,
								   marginRight: 15
							   }}
							   InputProps={{inputProps: {min: tier.minUser, max: 100000, textAlign: 'right'}}}
							   inputProps={{min: tier.minUser, style: {textAlign: 'right'}}}
							   classes={{root: classes.rootInput}}
							   onChange={e => {
								   const value = e.target.value
								   setData({
									   ...data,
									   numberOfUsers: value
								   })
								   setError(validateMinUser(value, tier.minUser) || validateChangeNumberOfUser(action, value, currentNumberOfUsers) || false)
							   }}
							   helperText={
								   (error && error.numberOfUsers)
									   ? translate(error.numberOfUsers, {
										   minUser: tier.minUser,
										   currUser: currentNumberOfUsers
									   })
									   : ""
							   }
					/>
				</Grid>

				{isDisplayRemoveUsersComp &&
				<div className={classes.rightControl}>
					<MultipleUserSelection filter={{subscriptionId: subscriptionId, status: 'ACTIVE'}}
										   onUsersSelected={onUsersSelected}/> {/*status: 'ACTIVE',*/}
				</div>
				}
				<Grid item xs={3} className={classNames(classes.leftLabel, classes.mt2)}></Grid>
				<Grid item xs={9} className={classNames(classes.rightControl, classes.mt2)}
					  style={{paddingTop: 0, paddingBottom: 0}}>
					<div style={{display: 'flex', justifyContent: 'center', width: '100%' }}>
						<div style={{ flex: 1 }}></div>
						<IconButton onClick={e => {
							submitOrder({periodId: data.selectedPeriod.id, numberOfUsers: data.numberOfUsers})
						}}><ForwardArrowIcon style={{width: 15.75}}/></IconButton>
					</div>

				</Grid>
			</Grid>

		</div>
	)

}

ReviewPlan.propTypes = {
	tier: PropTypes.object
};


const enhance = compose(
	translate,
	withStyles(styles),
	withWidth()
);

const Enhanced = enhance(ReviewPlan);

const ReviewPlanWithTheme = props => (
	<MuiThemeProvider theme={createTheme(lightTheme)}>
		<Enhanced {...props} />
	</MuiThemeProvider>
);

export default ReviewPlanWithTheme;

