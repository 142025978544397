import React from 'react'
import {compose} from "recompose";
import {connect} from "react-redux";
import {createTheme, MuiThemeProvider, withStyles} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {lightTheme} from "../../../layout/themes";
import {translate} from 'react-admin'
import qs from 'query-string'
import {
    setLoading,
    updateCurrentStep,
    updateHoveredPackage,
    updateOrderDetail,
    updateRemoveUsers,
    updateReviewPlan,
    updateSelectedPackage,
    updateSubscriptionDetail
} from "../homeReducers";
import StepBaseComponent from '../StepBaseComponent'
import {
    EDIT_PACKAGE,
    GET_EXIST_SUBSCRIPTION,
    GO_TO_PATH,
    LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION,
    LOAD_ORDER_DETAIL,
    LOAD_PERIODS_AND_UPDATE_REVIEW_PLAN,
    LOAD_PERIODS_BY_PACKAGE,
    LOAD_SUBSCRIPTION_DETAIL
} from "../homeSaga";
import ReviewPlan from '../ReviewPlan'
import {EDIT_ACTION, EXTEND_ACTION, UPGRADE_ACTION} from "../../../subscription/SubscriptionList";
import {preCheckPayment} from '../homeApi';

const styles = {}

const homeLink = '/my-account/subscription?'

class ReviewPlanContainer extends StepBaseComponent {

    constructor(props) {
        super(props);
        this.onReviewPlanSubmit = this.onReviewPlanSubmit.bind(this)
    }

    componentDidMount() {
        const { salePackages, loadSubscriptionDetail, loadCurrentActiveUsersSubscription } = this.props

        // Parse query
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        const { tierId } = query

        const selectedPackage = salePackages.filter(pack => pack.id === tierId)[0]
        this.props.updateSelectedPackage(selectedPackage)

        if (!!query.subscriptionId ) { // Case Extend/upgrade/edit subscription - we have subscriptionId in query
            loadSubscriptionDetail(query.subscriptionId)
            loadCurrentActiveUsersSubscription(query.subscriptionId)
        } else {
            updateSubscriptionDetail(undefined);
        }
    }

    onReviewPlanSubmit(data) {
        const { selectedPackage, subscribePackage, upgradePackage, extendPackage, editPackage, updateReviewPlan, updateCurrentStep, goToPath, history, updateRemoveUsers } = this.props

        const { action, subscriptionId, removeUsers, periodId, numberOfUsers } = data;

        if (action && action === UPGRADE_ACTION) {

            const nextQuery = {
                action,
                subscriptionId,
                numberOfUsers,
                periodId,
                tierId: selectedPackage.id,
                step: 2
            }
            updateCurrentStep(2)
            history.push(homeLink + qs.stringify(nextQuery))

        } else if (action === EXTEND_ACTION) {

            // update removeUsers To Store
            if (removeUsers && removeUsers.length > 0) {
                // log.debug('removeUsers', removeUsers);
                updateRemoveUsers(removeUsers);
            }

            const nextQuery = {
                action,
                subscriptionId,
                numberOfUsers,
                periodId,
                tierId: selectedPackage.id,
                step: 2,
                //removeUsers: removeUsers
            }
            updateCurrentStep(2)
            history.push(homeLink + qs.stringify(nextQuery))

        } else if (action === EDIT_ACTION) {

            preCheckPayment(subscriptionId).then((rsp) => {

                // log.debug('[PreCheck Payment] ', rsp)

                const { json } = rsp;
                let editQuery = {
                    action,
                    subscriptionId,
                    numberOfUsers,
                    periodId,
                    tierId: selectedPackage.id,
                    step: 2
                }
               
                if (json) {
                    // require payment
                    editQuery.step = 2;

                    let path = homeLink + qs.stringify(editQuery);

                    // log.debug('[PreCheck Payment] require payment', json, editQuery, history, path)

                    //updateCurrentStep(editQuery.step)

                    goToPath( { path: path, step: editQuery.step } );

                    updateCurrentStep(editQuery.step)

                    //history.push('/home?' + qs.stringify(editQuery))

                } else {
                    // log.debug('[PreCheck Payment] norequire payment', json)
                    editQuery.step = 3
                    editPackage(selectedPackage, 
                        false,
                        {
                            numberOfUsers: data.numberOfUsers,
                            paymentPlanIds: [selectedPackage.id, data.periodId],
                            subscriptionId: subscriptionId
                        })
                }

            })

        } else {

            updateReviewPlan(data)

            const nextQuery = {
                numberOfUsers,
                periodId,
                tierId: selectedPackage.id,
                step: 2
            }

            updateCurrentStep(2)

            history.push(homeLink + qs.stringify(nextQuery))
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

        if (!this.props.subscriptionDetail && !!nextProps.subscriptionDetail && !this.props.orderDetail) {
        //if (!!nextProps.subscriptionDetail) {
            // this.props.updateSelectedPackage(nextProps.subscriptionDetail.tierPaymentPlan)
            // After subscription detail load done, update order detail to render form
            const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

            let tierId, periodId
            let action = query.action;
            if (action == UPGRADE_ACTION) {
                // we have tierId from query string
                tierId = query.tierId
            } else {
                nextProps.subscriptionDetail.paymentPlanIds.forEach((tierOrPeriodId, i) => {
                    this.props.salePackages.forEach(pack => {
                        if (pack.id === tierOrPeriodId) {
                            tierId = tierOrPeriodId
                            periodId = nextProps.subscriptionDetail.paymentPlanIds[1 - i]
                        }
                    })
                })
            }

            const { paymentPlanIds, numberOfUsers } = nextProps.subscriptionDetail

            if (this.props.periods && this.props.periods.length > 0)
                this.props.updateReviewPlan({ periodId, numberOfUsers })
            else
                this.props.loadPeriodsAndUpdateReviewPlan(tierId, periodId, nextProps.subscriptionDetail.numberOfUsers)

            const prevSelectedPackage = this.props.selectedPackage
            if (!prevSelectedPackage || prevSelectedPackage.id !== nextProps.subscriptionDetail.tierPaymentPlan.id) {
                //alert('Must update selected package')
                // log.debug('Must update selected package')
            }
        }
    }

    render() {
        const { selectedPackage, periods, subscriptionDetail } = this.props

        if (!selectedPackage || periods.length === 0)
            return null

        return (
            <React.Fragment>
                <ReviewPlan { ...this.props } tier={selectedPackage} onSubmit={this.onReviewPlanSubmit} />
            </React.Fragment>
        );
    }

    componentWillUnmount() {
        // this.props.updateSelectedPackage(false)
        this.props.updateHoveredPackage(this.props.salePackages[0])
        this.props.updateSubscriptionDetail(false)
    }


}

const mapStateToProps = state => {
    return {
        loading: state.home.loading,
        salePackages: state.home.salePackages,
        selectedPackage: state.home.selectedPackage,
        periods: state.home.periods,
        reviewPlan: state.home.reviewPlan,
        subscriptionDetail: state.home.subscriptionDetail,
        orderDetail: state.home.orderDetail,
        currentActiveUsersSubscription: state.home.currentActiveUsersSubscription,
        subscriptions: state.home.subscriptions
    }
};

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
            updateSelectedPackage,
            loadPeriods: (tierPlanId) => ({
                type: LOAD_PERIODS_BY_PACKAGE,
                payload: tierPlanId
            }),
            updateOrderDetail,
            updateCurrentStep,
            updateHoveredPackage,
            updateReviewPlan,
            updateSubscriptionDetail,
            updateRemoveUsers,
            setLoading,
            loadSubscriptionDetail: (subscriptionId) => ({
                type: LOAD_SUBSCRIPTION_DETAIL,
                payload: subscriptionId
            }),
            loadOrderDetail: (orderId) => ({
                type: LOAD_ORDER_DETAIL,
                payload: orderId
            }),
            loadCurrentActiveUsersSubscription: (subscriptionId) => ({
                type: LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION,
                payload: subscriptionId
            }),
            loadPeriodsAndUpdateReviewPlan: (tierId, periodId, numberOfUsers) => ({
                type: LOAD_PERIODS_AND_UPDATE_REVIEW_PLAN,
                payload: { tierId, periodId, numberOfUsers }
            }),
            editPackage: (pack, requirePayment, additionalData) => ({
                type: EDIT_PACKAGE, payload: {
                    selectedPackage: pack,
                    requirePayment,
                    additionalData: additionalData
                }
            }),
            goToPath: (data) => ({
                type: GO_TO_PATH, payload: data
            }),
            getExistSubscription: () => {
                return {
                    type: GET_EXIST_SUBSCRIPTION
                }
            }
        }
    ),
    withStyles(styles),
    withWidth()
);

const EnhancedReviewPlanContainer = enhance(ReviewPlanContainer);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const ReviewPlanContainerWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedReviewPlanContainer {...props} />
    </MuiThemeProvider>
);

export default ReviewPlanContainerWithTheme;