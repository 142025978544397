import { withStyles } from '@material-ui/core/styles';
import React, { Component, Children, cloneElement, Fragment } from 'react';
import {
    Datagrid,
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    refreshView as refreshViewAction,
    ReferenceField,
    translate, ListActions, TopToolbar, CreateActions, Button, ListButton, CreateButton, SaveButton, Toolbar,
} from 'react-admin';
import DashboardPageWrapper from '../components/DashboardPageWrapper';
import {Add, Edit, PersonAdd, RemoveRedEye, TrendingUp as SubscribeIcon} from '@material-ui/icons';
import VKeyListButton from '../components/VkeyListButton';
import SecurityChecker from '../utils/security';
import CustomerReferenceField from '../components/CustomerReferenceField';
import LocalStorage from '../utils/services/storage';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import VkeyAmountField from '../components/VkeyAmountField';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { IDM_DASHBOARD, MY_ACC_DASHBOARD } from '../utils/services/storage';
import OrderStatusField from './OrderStatusField';
import { GET_HIGHER_PLAN } from './data-provider'
import dataProvider from '../dataProvider'
import { stringify } from "query-string";
import {faFileCsv, faUpload} from '@fortawesome/free-solid-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlanNameField from './PlanNameField';
import Home from "../external/home";

export const STATUS_ORDER_APPROVED = "APPROVED"

export const STATUS_ORDER_SUBMITTED = "SUBMITTED"

export const FREE_PLAN_TYPE = "FREE";
export const NORMAL_PLAN_TYPE = "NORMAL";

export const SUB_STATUS_ACTIVE = "ACTIVE"

export const UPGRADE_ACTION = "upgrade"
export const EXTEND_ACTION = "extend"
export const EDIT_ACTION = "edit"

const homeLink = '/my-account/subscription'

const ServiceInstanceField = ({ source, sortable, record = {}, ...rest }) => {

    let serviceInstanceObj = get(record, source);

    if ((serviceInstanceObj != undefined && serviceInstanceObj != null)) {
        return (
            <Link to={`/service-launch/` + serviceInstanceObj.id}>{serviceInstanceObj.instanceName}</Link>
        );
    } else {
        return (<span></span>) ;
    }

};

const OrderListActionToolbar = withStyles({
    toolbar: {
        alignItems: 'center',
        display: 'flex',
    },
})(({ classes, children, ...props }) => (
    <div className={classes.toolbar}>
        {(children && Children.map(children, button => (button == null || button === 0 || button === '0') ? null : cloneElement(button, props))) || null}
    </div>
));

const ConditionalActionsField = ({ translate, record, dashboard, history, editSubscription, extendSubscription, upgradeSubscription, expireDateRangeCheck, ...rest }) =>
{
    //// log.debug('[ConditionalActionsField] record, rest ', record, rest)

    // display extend button when will be expire soon - within 2week
    //const expireDate = record.expireDate;
    //const extendButtonEnableFlag = (expireDate - new Date().getTime()) <= expireDateRangeCheck;
    //// log.debug('extendButtonEnableFlag', extendButtonEnableFlag, expireDateRangeCheck)

    //const extendButtonEnableFlag = false;
    //const editButtonEnableFlag = false;

    if (dashboard === MY_ACC_DASHBOARD && record.tierPlanType === NORMAL_PLAN_TYPE) {

        return (
            <Fragment>
                <VKeyListButton title={translate('resources.subscription.buttons.detailSubscription.label')}
                                record={record} 
                                handleClick={record => {
                                    history.push(`/my-account/subscription/${record.id}`)
                                }}
                                //rowAction={!SecurityChecker(permissions, 'account', ['modify', 'configure'], 'OR')}
                >
                    <RemoveRedEye />
                </VKeyListButton>
            </Fragment>
        )
    } else if (record.tierPlanType === FREE_PLAN_TYPE) {
        // Vkey approve
        return (
            <Fragment>
                <VKeyListButton title={translate('resources.subscription.buttons.detailSubscription.label')} 
                                record={record} 
                                handleClick={record => {
                                    history.push(`/my-account/subscription/${record.id}`)
                                }}
                                //rowAction={!SecurityChecker(permissions, 'account', ['modify', 'configure'], 'OR')}
                >
                    <RemoveRedEye />
                </VKeyListButton>
                
            </Fragment>
        )
    }

    return (<div />)
}


PlanNameField.defaultProps = { label: 'Plan' };

const styles = {
    myAccountOrder: {

    }
}

class SubscriptionList extends Component {

    state = {
        customerId: null,
        expireDateRangeCheck: 14 * 24 * 60 * 60 * 1000
    }

    constructor(props) {
        super(props)
        //this.cancelOrder = this.cancelOrder.bind(this)
        //this.approvePayment = this.approvePayment.bind(this)
        this.handleSubscribe = this.handleSubscribe.bind(this)
        this.extendSubscription = this.extendSubscription.bind(this)
        this.upgradeSubscription = this.upgradeSubscription.bind(this)
        this.editSubscription = this.editSubscription.bind(this)
    }

    async UNSAFE_componentWillMount() {

        const customerId = LocalStorage.instance.getCustomerId();

        this.setState({
            customerId: customerId
        });

    }

    componentWillUnmount() {
    }

    handleSubscribe(record) {
        // log.debug('subscribe package ', record)
        const { history } = this.props
        history.push(homeLink);
    }

    editSubscription(subscription) {
        // log.debug('edit subscription', subscription);

        const { history } = this.props;

        // extend current subscription
        let query = {
            tierId: subscription.tierPaymentPlan.id,
            action: EDIT_ACTION,
            subscriptionId: subscription.id,
            step: 1
        }

        history.push(homeLink+`?${stringify(query)}`)

    }

    extendSubscription(subscription) {
        // log.debug('extend subscription', subscription);

        const { history } = this.props;

        // extend current subscription
        let query = {
            tierId: subscription.tierPaymentPlan.id,
            action: EXTEND_ACTION,
            subscriptionId: subscription.id,
            step: 1
        }

        history.push(homeLink+`?${stringify(query)}`)

    }

    upgradeSubscription(subscription) {
        // log.debug('upgrade subscription', subscription);

        // free -> higher plan
        const { history, dataProvider } = this.props;

        dataProvider(GET_HIGHER_PLAN, 'subscription', { tierPlanId : subscription.tierPaymentPlan.id },
            {
                onSuccess: {

                },
                onFailure: {
                    notification: { body: 'Error: Failed to get higher plan', level: 'warning' }
                }
            }).then(rsp => {
                
                // log.debug('get higher plan response', rsp);

                const higherPlan = rsp.data[0];

                let query = {
                    tierId: higherPlan.id,
                    //upgradeFromTierId: subscription.tierPaymentPlan.id,
                    //upgradeFromTierPlanType: FREE_PLAN_TYPE,
                    action: UPGRADE_ACTION,
                    subscriptionId: subscription.id,
                    step: 1
                }

                history.push(homeLink+`?${stringify(query)}`)
            });
        
    }

    render() {

        const { history, dashboard, permissions, translate, loading } = this.props;

        const { expireDateRangeCheck } = this.state;

        //// log.debug('orderlist ===>', permissions)

        return (
            <DashboardPageWrapper title={false} didMount={wrapper => this.wrapper = wrapper}>
                <List
                    {...this.props}
                    //filters={<OrderFilter />}
                    filters={false}
                    //bulkActionButtons={<AccountBulkActionsToolbar translate={translate} />}
                    bulkActionButtons={false}
                    exporter={false}
                    empty={<Home {...this.props}/>}
                    className={dashboard === MY_ACC_DASHBOARD ? "myaccount-list": null}
                >
                    <>
                    <Home {...this.props}/>
                    <Datagrid>
                        {/*<ImageField source="serviceTemplate.iconUrl" title="icon" label={''} />*/}
                        {/*<TextField sortable={false} source="serviceTemplate.templateName" label={'resources.orders.fields.service.label'} />*/}
                        {/*<ServiceInstanceField sortable={false} source="serviceInstance" label={'resources.serviceLaunchs.fields.instanceName.label'} />*/}
                        <ReferenceField source="customerId" reference="customer" allowEmpty linkType={false}>
                            <TextField sortable={false} source="companyName"  />
                        </ReferenceField>
                        {/*<PlanNameField />*/}
                        {/*<VkeyAmountField sortable={false} source="amount" currencySource="currency" label={translate('resources.orders.fields.amount.label')} />*/}
                        <DateField sortable={false} source="expireDate"
                                   label={translate('resources.orders.fields.expireDate.label')}/>
                        <OrderStatusField sortable={false} source="status"
                                          label={translate('resources.orders.fields.status.label')}/>

                        <ConditionalActionsField
                            translate={translate}
                            dashboard={dashboard}
                            history={history}
                            editSubscription={this.editSubscription}
                            extendSubscription={this.extendSubscription}
                            upgradeSubscription={this.upgradeSubscription}
                            expireDateRangeCheck={expireDateRangeCheck}/>


                    </Datagrid>
                    </>
                </List>
            </DashboardPageWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.admin.loading
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
            refreshView: refreshViewAction
        }
    ),
    withStyles(styles)
);

export default enhance(SubscriptionList);