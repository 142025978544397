import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
    getListPackages, getListFeatures, subscribePackage as subscribePackageApi,
    upgradePackage as upgradePackageApi,
    extendPackage as extendPackageApi,
    editPackage as editPackageApi,
    getOrderById, getListPeriodsByPlanId,
    payAnOrder as payAnOrderApi,
    getSubscriptionById,
    getCurrentActiveUsersOfSubscription,
    getListCustomerCards,
    deleteCard as deleteCardApi,
    calculateOrderAmount as calculateOrderAmountApi,
    preCheckPayment as preCheckPaymentApi,
    setAsDefaultCard,
    getExistSubcription as getExistSubcriptionApi
} from './homeApi'
import {
    UPDATE_CARDS,
    UPDATE_LIST_FEATURES,
    UPDATE_LIST_SALE_PACKAGES,
    UPDATE_LOADING,
    UPDATE_MESSAGE,
    UPDATE_ORDER_DETAIL,
    UPDATE_PERIODS,
    UPDATE_SUBSCRIPTION_DETAIL,
    UPDATE_CURRENT_ACTIVE_USERS_SUBSCRIPTION,
    UPDATE_REVIEW_PLAN,
    UPDATE_PRE_CHECK_PAYMENT,
    UPDATE_CURRENT_STEP, UPDATE_EXIST_SUBSCRIPTION
} from './homeReducers'
import log from "../../utils/log";

import qs from 'query-string'

import { PACKAGE_TYPE_ENTERPRISE, PACKAGE_TYPE_NORMAL, PACKAGE_TYPE_FREE } from './constant'

export const LOAD_PACKAGES_AND_FEATURES = 'LOAD_PACKAGES_AND_FEATURES'

export const SUBSCRIBE_PACKAGE = 'SUBSCRIBE_PACKAGE'

export const LOAD_ORDER_DETAIL = 'LOAD_ORDER_DETAIL'

export const LOAD_PERIODS_BY_PACKAGE = 'LOAD_PERIODS_BY_PACKAGE'

export const LOAD_SUBSCRIPTION_DETAIL = 'LOAD_SUBSCRIPTION_DETAIL'

export const LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION = 'LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION'

export const UPGRADE_PACKAGE = 'UPGRADE_PACKAGE'

export const EXTEND_PACKAGE = 'EXTEND_PACKAGE'

export const EDIT_PACKAGE = 'EDIT_PACKAGE'

// check to require payment step or not
export const PRE_CHECK_PAYMENT = 'PRE_CHECK_PAYMENT'

export const GO_TO_PATH = 'GO_TO_PATH'

// STRIPE --------
export const PAY_AN_ORDER = 'PAY_AN_ORDER'

export const LOAD_CUSTOMER_CARDS = 'LOAD_CUSTOMER_CARDS'

export const DELETE_CARD = 'DELETE_CARD'

export const SET_PRIMARY_CARD = 'SET_PRIMARY_CARD'

export const CALCULATION_ORDER_AMOUNT = 'CALCULATION_ORDER_AMOUNT'

export const LOAD_PERIODS_AND_UPDATE_REVIEW_PLAN = 'LOAD_PERIODS_AND_UPDATE_REVIEW_PLAN'

export const GET_EXIST_SUBSCRIPTION = "GET_EXIST_SUBSCRIPTION"

function* loadPackagesAndFeatures() {
    yield put({ type: UPDATE_LOADING, data: true })
    // get list sale package
    let rsp = yield call(getListPackages)
    yield put({ type: UPDATE_LIST_SALE_PACKAGES, data: rsp.json })
    // Get list feature for render table
    rsp = yield call(getListFeatures)
    yield put({ type: UPDATE_LIST_FEATURES, data: rsp.json })

    yield put({ type: UPDATE_LOADING, data: false })
}

function* loadPeriodsByPackage(action) {
    log.warn('Load periods by package id: ', action.payload)
    yield put({ type: UPDATE_LOADING, data: true })
    let { json } = yield call(getListPeriodsByPlanId, action.payload)
    yield put({ type: UPDATE_PERIODS, data: json })
    yield put({ type: UPDATE_LOADING, data: false })
}

function* subscribePackage(action) {
    try {
        log.info("subscribe package ", action)
        yield put({ type: UPDATE_LOADING, data: true })
        let { json } = yield call(subscribePackageApi, action.payload) // OrderDetail
        /*yield put({ type: UPDATE_ORDER_DETAIL, data: false }) */
        yield put({ type: UPDATE_ORDER_DETAIL, data: json }) // Update OrderDetail to store
        // log.debug('[HomeSaga] subscribePackage done with payload', action.payload)
        if (action.payload.selectedPackage.tierPlanType === PACKAGE_TYPE_ENTERPRISE ||
            action.payload.selectedPackage.tierPlanType === PACKAGE_TYPE_FREE) {
            window.globalHistory.push(`/my-account/subscription?` + qs.stringify({
                orderId: json.id,
                done: true,
                // step: 1
            }))
        } else if (action.payload.additionalData.stripeTokenId || action.payload.additionalData.sourceId) { // For pro package, need payment
            log.warn("Execute payment process ... ")
            // yield call(loadCustomerCards)
            // window.globalHistory.push(`/home?orderId=${json.id}`)
            // log.debug('[HomeSaga] subscribePackage continue call payment', action.payload)
            yield call(payAnOrder, { payload: { ...action.payload.additionalData, orderId: json.id } })
        } else {
            log.warn("Skip payment process, will pay later !!! ")
            window.globalHistory.push(`/my-account/subscription?` + qs.stringify({
                orderId: json.id,
                done: true,
                step: 3
                // step: 1
            }))
        }
        yield put({ type: UPDATE_LOADING, data: false })
    } catch (e) {
        log.warn('subscribePackage got exception', e)
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
        yield put({ type: UPDATE_LOADING, data: false })
    }
}

function* upgradePackage(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })

        // let { json } = yield call(upgradePackageApi, action.payload)

        // yield call(loadCustomerCards)
        // yield put({ type: UPDATE_LOADING, data: false })
        // window.globalHistory.push(`/home?orderId=${json.id}`)

        let { json } = yield call(upgradePackageApi, action.payload)
        yield call(payAnOrder, { payload: { ...action.payload.additionalData, orderId: json.id } })
        yield put({ type: UPDATE_LOADING, data: false })

    } catch (e) {
        log.warn('upgradePackage got exception', e)
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
        yield put({ type: UPDATE_LOADING, data: false })
    }
}

function* extendPackage(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })

        //let { json } = yield call(extendPackageApi, action.payload)

        //yield call(loadCustomerCards)
        //yield put({ type: UPDATE_LOADING, data: false })
        //window.globalHistory.push(`/home?orderId=${json.id}`)

        let { json } = yield call(extendPackageApi, action.payload)
        yield call(payAnOrder, { payload: { ...action.payload.additionalData, orderId: json.id } })
        yield put({ type: UPDATE_LOADING, data: false })

    } catch (e) {
        log.warn('extendPackage got exception', e)
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
        yield put({ type: UPDATE_LOADING, data: false })
    }
}

function* editPackage(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })

        // let { json } = yield call(editPackageApi, action.payload)

        // yield call(loadCustomerCards)
        // yield put({ type: UPDATE_LOADING, data: false })
        // window.globalHistory.push(`/home?orderId=${json.id}`)

        // log.debug("[editPackage] ", action);

        if (action.payload.requirePayment) {
            let { json } = yield call(editPackageApi, action.payload)
            yield call(payAnOrder, { payload: { ...action.payload.additionalData, orderId: json.id } })
        } else {
            let { json } = yield call(editPackageApi, action.payload)
            window.globalHistory.push(`/my-account/subscription?orderId=${json.id}&done=true`)
        }

        yield put({ type: UPDATE_LOADING, data: false })

    } catch (e) {
        log.warn('extendPackage got exception', e)
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
        yield put({ type: UPDATE_LOADING, data: false })
    }
}

function* loadCustomerCards() {
    try {
        yield put({type: UPDATE_LOADING, data: true})
        let {json} = yield call(getListCustomerCards)
        yield put({ type: UPDATE_CARDS, data: json })
    } catch (e) {
        yield put({ type: UPDATE_CARDS, data: [] })
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })

}

function* loadOrderDetail(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        let { json } = yield call (getOrderById, action.payload)
        yield put({ type: UPDATE_ORDER_DETAIL, data: json }) // Update OrderDetail to store
        yield call(loadCustomerCards)
    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })
}

function* loadSubscriptionDetail(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        let { json } = yield call (getSubscriptionById, action.payload)
        yield put({ type: UPDATE_SUBSCRIPTION_DETAIL, data: json }) // Update SubscriptionDetail to store
    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })
}

function* loadCurrentActiveUserOfSubscription(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        let { json } = yield call (getCurrentActiveUsersOfSubscription, action.payload)
        yield put({ type: UPDATE_CURRENT_ACTIVE_USERS_SUBSCRIPTION, data: json }) // Update SubscriptionDetail to store
    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })
}

function* preCheckPayment(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        let { json } = yield call (preCheckPaymentApi, action.payload)
        yield put({ type: UPDATE_PRE_CHECK_PAYMENT, data: json }) // Update SubscriptionDetail to store
    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })
}

function* payAnOrder(action) {
    // log.debug('[HomeSaga] payAnOrder ', action)
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        let { json } = yield call (payAnOrderApi, action.payload)
        yield put({ type: UPDATE_ORDER_DETAIL, data: json }) // Update OrderDetail to store
        window.globalHistory.push(`/my-account/subscription?orderId=${action.payload.orderId}&done=true`)
    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })
}

function* deleteCard(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        yield call (deleteCardApi, action.payload)
        yield call(loadCustomerCards)
    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })
}

function* setPrimary(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        yield call (setAsDefaultCard, action.payload)
        yield call(loadCustomerCards)
    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })
}

function* calculateOrderAmount(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        const { json } = yield call (calculateOrderAmountApi, action.payload)
        // log.debug('Calculate amount done ', json)
        yield put({ type: UPDATE_ORDER_DETAIL, data: { ...action.payload, amount: json.finalAmount } })
    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
    }
    yield put({ type: UPDATE_LOADING, data: false })

}

function* loadPeriodsAndUpdateReviewPlan(action) {
    log.warn('loadPeriodsAndUpdateReviewPlan ', action)
    yield call(loadPeriodsByPackage, { payload: action.payload.tierId })
    // yield put({ type: UPDATE_REVIEW_PLAN, data: false })
    yield put({ type: UPDATE_REVIEW_PLAN, data: { ...action.payload } })
}

function* gotoPath(action) {
    try {
        yield put({ type: UPDATE_LOADING, data: true })

        // log.debug("[gotoPath] ", action);

        //yield put( { type: UPDATE_CURRENT_STEP, data: action.payload.step } )

        window.globalHistory.push(`${action.payload.path}`)

        yield put({ type: UPDATE_LOADING, data: false })

    } catch (e) {
        log.warn('gotoPath got exception', e)
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
        yield put({ type: UPDATE_LOADING, data: false })
    }
}

function* getExistSubscription() {
    try {
        yield put({ type: UPDATE_LOADING, data: true })
        const resp  = yield call(getExistSubcriptionApi)

        yield put({ type: UPDATE_EXIST_SUBSCRIPTION, data: resp.json.contents })
        yield put({ type: UPDATE_LOADING, data: false })

    } catch (e) {
        if (e.body) {
            yield put({ type: UPDATE_MESSAGE, data: { ...e.body, type: 'error' } })
        }
        yield put({ type: UPDATE_LOADING, data: false })
    }
}

function* mySaga() {
    yield takeLatest(LOAD_PACKAGES_AND_FEATURES, loadPackagesAndFeatures);
    yield takeEvery(SUBSCRIBE_PACKAGE, subscribePackage)
    yield takeEvery(UPGRADE_PACKAGE, upgradePackage)
    yield takeEvery(EXTEND_PACKAGE, extendPackage)
    yield takeEvery(EDIT_PACKAGE, editPackage)
    yield takeLatest(LOAD_ORDER_DETAIL, loadOrderDetail)
    yield takeEvery(LOAD_PERIODS_BY_PACKAGE, loadPeriodsByPackage)
    yield takeLatest(LOAD_SUBSCRIPTION_DETAIL, loadSubscriptionDetail)
    yield takeLatest(LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION, loadCurrentActiveUserOfSubscription)
    yield takeLatest(PRE_CHECK_PAYMENT, preCheckPayment)
    yield takeLatest(GO_TO_PATH, gotoPath)

    // For stripe payment
    yield takeLatest(LOAD_CUSTOMER_CARDS, loadCustomerCards)
    yield takeLatest(PAY_AN_ORDER, payAnOrder)
    yield takeEvery(DELETE_CARD, deleteCard)
    yield takeEvery(SET_PRIMARY_CARD, setPrimary)
    yield takeLatest(CALCULATION_ORDER_AMOUNT, calculateOrderAmount)
    yield takeLatest(LOAD_PERIODS_AND_UPDATE_REVIEW_PLAN, loadPeriodsAndUpdateReviewPlan)
    yield takeLatest(GET_EXIST_SUBSCRIPTION, getExistSubscription)
}

export default mySaga;
