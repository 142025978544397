import React from 'react';
import {Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import ServiceInstanceCreate from "./service-launch/ServiceInstanceCreate";
import Scheme from "./external/scheme";
import Home from './external/home'
import Deeplink from "./external/deeplink";
import ActivationCodeList from "./internal/activation-code/ListActivationCode";
import OTPList from "./internal/otp/ListOTP";
import {Redirect} from "react-router";

const Loading = () => null;

const DynamicImport = (LoaderComponent) => Loadable({
    loader: LoaderComponent,
    loading: () => null
});

const Docs = Loadable({
    loader: () => import('./external/docs'),
    loading: Loading
});

const DocsV2 = Loadable({
    loader: () => import('./external/docs_v2'),
    loading: Loading
});

/*const Home = Loadable({
    loader: () => import('./external/home'),
    loading: Loading
});*/

const AccountProfile = Loadable({
    loader: () => import('./external/myaccount/AccountProfile'),
    loading: Loading
});

const AccountSecurity = Loadable({
    loader: () => import('./external/myaccount/AccountSecurity'),
    loading: Loading
});

const MyAccChangePassword = Loadable({
    loader: () => import('./external/myaccount/ChangePassword'),
    loading: Loading
});

const MyAccOrderDetail = Loadable({
    loader: () => import('./external/myaccount/OrderDetail'),
    loading: Loading
});

const MyAccOrderList = Loadable({
    loader: () => import('./external/myaccount/Orders'),
    loading: Loading
});

const Configuration = Loadable({
    loader: () => import('./configuration/Configuration'),
    loading: Loading
});

const Ldap = Loadable({
    loader: () => import('./internal/ldaps'),
    loading: Loading
});

const BuyService = Loadable({
    loader: () => import('./external/buyservice'),
    loading: Loading
});

const SignUp = Loadable({
    loader: () => import('./external/signup/SignUp'),
    loading: Loading
});

const SignUpCompleted  = Loadable({
    loader: () => import('./external/signup/SignUpCompleted'),
    loading: Loading
});

const ForgotPassword = Loadable({
    loader: () => import('./external/forgot_password/SubmitForgotPass'),
    loading: Loading
});

const NewPassword = Loadable({
    loader: () => import('./external/forgot_password/NewPassword'),
    loading: Loading
});

const SignUpActivation = Loadable({
    loader: () => import('./external/signup/SignUpActivation'),
    loading: Loading
});

const AssetPermission = Loadable({
    loader: () => import('./asset/AssetPermission'),
    loading: Loading
});

const ChangePassword = Loadable({
    loader: () => import('./account/change-password'),
    loading: Loading
});

const SubscriptionDetail = Loadable({
    loader: () => import('./subscription/SubscriptionDetail'),
    loading: Loading
});

const SubscriptionsList = Loadable({
    loader: () => import('./external/myaccount/Subscriptions'),
    loading: Loading
});

const AccountSearchListIframe = Loadable({
    loader: () => import('./role/AccountSearchListIframe'),
    loading: Loading
});

const UserServiceSearchListIframe = Loadable({
    loader: () => import('./service-launch/UserServiceSearchListIframe'),
    loading: Loading
});

const LdapEditNoLayout = Loadable({
    loader: () => import('./internal/wizard_v2/components/LdapEditNoLayout'),
    loading: Loading
});

const WizardNoLayout = Loadable({
    loader: () => import('./internal/wizard_v2'),
    loading: Loading
});

const RefreshAccountWithToken = Loadable({
    loader: () => import('./external/oidc/RefreshAccountWithToken'),
    loading: Loading
});

const ContactUs = Loadable({
    loader: () => import('./external/contact-us'),
    loading: Loading
});

const PopupResultHandler = Loadable({
    loader: () => import('./external/PopupResultHandler'),
    loading: Loading
});

const OAuth2Handler = Loadable({
    loader: () => import('./layout/OAuth2Handler'),
    loading: Loading
});

const EmailLinkHandler = Loadable({
    loader: () => import('./external/EmailLinkHandler'),
    loading: Loading
});

const UserResetByBackupEmail = Loadable({
    loader: () => import('./external/UserResetByBackupEmail'),
    loading: Loading
});

export default [
    <Route exact path="/" render={() => <Redirect to="/login" />}/>,
    <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/ad-config" component={Ldap} />,
    <Route exact path="/launch-now/:serviceTemplateId" component={BuyService} noLayout />,
    <Route exact path="/signup" component={SignUp} noLayout />,
    <Route exact path="/signup/completed" component={SignUpCompleted} noLayout />,
    <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
    <Route exact path="/account/reset-password" component={NewPassword} noLayout />,
    <Route exact path="/signup-activate/:token" component={SignUpActivation} noLayout />,
    <Route exact path="/docs/:page/:helpModule" component={Docs} noLayout />,
    // <Route exact path="/docs" component={DocsV2} noLayout />,
    <Route exact path="/docs/:page/:helpModule/:pageId" component={Docs} noLayout />,
    // <Route exact path="/docs/help/:helpModule/:page" component={Docs} noLayout />,
    // <Route exact path="/docs/intro/:helpModule/:page" component={Docs} noLayout />,
    // <Route exact path="/docs/help//:helpModule" component={Docs} noLayout />,
    // <Route exact path="/vos-cloud" component={VosCloudCOnfig} />,

    // <Route path="/service-launch/:serviceTemplateId" component={ServiceLaunch} />,

    <Route path="/asset/:assetId/permission" component={AssetPermission} />,
    
    <Route path="/profile/change-password" component={ChangePassword} />,

    <Route path="/my-account/profile" component={AccountProfile} />,
    <Route path="/my-account/change-password" component={MyAccChangePassword} />,
    <Route path="/my-account/security" component={AccountSecurity} />,
    <Route path="/my-account/orders/:id" component={MyAccOrderDetail} />,
    <Route path="/my-account/orders" component={MyAccOrderList} />,
    <Route path="/my-account/subscription/:id" component={SubscriptionDetail} />,
    <Route path="/my-account/subscription" component={SubscriptionsList} />,
    
    <Route path="/iframe/account-list" component={AccountSearchListIframe} noLayout />,
    <Route path="/iframe/user-list" component={UserServiceSearchListIframe} noLayout />,
    <Route path="/iframe/ldap-edit/:id" component={LdapEditNoLayout} noLayout />,
    <Route path="/iframe/wizard/:serviceInstanceId" component={WizardNoLayout} noLayout />,

    <Route path="/oidc/2fa-callback" component={RefreshAccountWithToken} noLayout />,
    <Route path="/service-launch/create/:templateId" component={ServiceInstanceCreate} />,
    <Route path="/scheme" component={Scheme} noLayout />,
    <Route path="/contact-us" component={ContactUs} noLayout />,
    <Route path="/popup-result-handler/:action" component={PopupResultHandler} noLayout />,
    <Route path="/popup-result-handler" component={PopupResultHandler} noLayout />,
    <Route path="/onboarding" component={Deeplink} noLayout />,

    <Route path="/activation-code/:tokenPackId" component={ActivationCodeList} />,
    <Route path="/otp" component={OTPList}/>,
    <Route path="/oauth2/handler" component={OAuth2Handler} noLayout />,
    <Route path="/verify-personal-email/:jwtToken" component={EmailLinkHandler} noLayout />,
    <Route path="/reset-user-by-backup-email/:jwtToken" component={UserResetByBackupEmail} noLayout />,
    
];
